import React, { Component } from 'react';
import Slider from "react-slick";
import { Link } from "react-router-dom";

class HomeRooms extends Component {
  render() {
   
    const rooms = this.props.rooms;
    
    console.log(rooms)

    return (
      <>
        <h1 className="fw-500 mb-0 mt-0 font-md text-grey-900 text-center pt-3 pb-3">Leitura Coletiva em destaque</h1>
        {rooms.map((room, index) => (
          <div key={index} className="col-md-6 col-sm-6">
            <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-0 mt-3 mb-3">
              <Link to={`sala/${room.slug}`}><div className="card-body position-relative h100 bg-image-cover bg-image-center" style={{ backgroundImage: `url("${room.imagemUrl}")` }} /></Link>
              <div className="card-body d-block w-100 pe-4 pb-4 pt-0 text-left position-relative">
                <div className="clearfix" />
                <Link to={`sala/${room.slug}`}><h4 className="fw-700 font-xsss mt-3 mb-1">{room.nome}</h4></Link>
                <Link to={`sala/${room.slug}`}><span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">{room.categoria}</span></Link>
              </div>
            </div>
          </div>
        ))}
        <div className="pt-3 pb-4">
          <Link to="/salas" className="p-2 lh-28 w-100 d-block bg-grey text-grey-800 text-center font-xssss fw-700 rounded-xl">
            <i className="feather-external-link font-xss me-2" /> Mais salas
          </Link>
        </div>
      </>
    );
  }
}

export default HomeRooms;
