import * as data from '../../data/requests';
import { users } from '../reducers/users';
import { login } from './session';
import { errorNotification, successNotification } from './communication';

export const { updateUsers } = users.actions;

export const fetchLoggedUser = () => async dispatch => {
  try {
    const response = await data.get('usuario');
    dispatch(login(response.data));
  } catch (err) {
    dispatch(errorNotification('Erro ao Recuperar Usuário.'));
  }
};

export const updateProfileUser = params => async dispatch => {
  try {
    const response = await data.put('usuario', params);
    if (response.status === 201) {
      dispatch(fetchLoggedUser());
      dispatch(successNotification('Perfil Atualizado com Sucesso!'));
    }
  } catch (error) {
    console.error('Erro ao Atualizar Perfil.');
  }
};

export const updateAvatarUser = params => async dispatch => {
  try {
    const response = await data.postForm('usuario/avatar', params);
    if (response.status === 201) {
      dispatch(fetchLoggedUser());
      dispatch(successNotification('Avatar Atualizado com Sucesso!'));
    }
  } catch (error) {
    console.error('Erro ao Atualizar Avatar.');
  }
};

export const updatePasswordUser = params => async dispatch => {
  try {
    const response = await data.put('usuario/senha', params);
    if (response.status === 201) {
      dispatch(fetchLoggedUser());
      dispatch(successNotification('Senha Atualizada com Sucesso!'));
    }
  } catch (error) {
    console.error('Erro ao Atualizar Senha.');
  }
};

export const updateEmaildUser = params => async dispatch => {
  try {
    const response = await data.put('usuario/email', params);
    if (response.status === 201) {
      dispatch(fetchLoggedUser());
      dispatch(successNotification('Email Atualizado com Sucesso!'));
    }
  } catch (error) {
    console.error('Erro ao Atualizar Email.');
  }
};
