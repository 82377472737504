import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import Dropzone from 'react-dropzone';

import { insertFeed } from '../../../../redux/actions/feeds';

import Header from "../../../layout/header/Header";

const FeedAdd = () => {
  const dispatch = useDispatch();

  let { id } = useParams();

  const [logoUrl, setLogoUrl] = useState(undefined);
  const [image, setImage] = useState(undefined);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onChangeImage = (file) => {
    const reader = new FileReader();
    setImage(file);

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setLogoUrl(reader.result);
    }
  }

  const onSubmit = (form) => {
    const {
      conteudo
    } = form;

    const formData = new FormData();

    if (image) {
      formData.append('midia', image, image.name);
    };

    formData.append('conteudo', conteudo);
    formData.append('tipo', 1);
    formData.append('fase', id);

    dispatch(insertFeed(id, formData));
  }

  return (
    <>
      <Header backPage={`/fase/${id}`} titlePage={`Add Feeds`} />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom-custom">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-xl-12">
                <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="card-body d-flex justify-content-between align-items-end p-0">
                          <div className="form-group mb-0 w-100">
                            <label className="mont-fnt fw-600 font-xsss mb-2">Capa *</label>
                            <Dropzone onDrop={files => onChangeImage(files[0])}>
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div {...getRootProps()}>
                                    <div className="col-lg-12 mb-3">
                                      <div className="card mt-3 border-0">
                                        <input
                                          {...getInputProps()}
                                          name="logo"
                                          type="file"
                                          accept="image/png, image/gif, image/jpeg"
                                          onChange={(event) => onChangeImage(event.target.files[0])}
                                          className="form-control"
                                        />
                                        <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">

                                          {
                                            logoUrl ?
                                              (<div className="row justify-content-center">
                                                <div className="col-lg-4 text-center">
                                                  <figure className="avatar ms-auto me-auto mb-0 mt-2 w100">
                                                    <img
                                                      alt="logo-editora"
                                                      className="shadow-sm rounded-3 w-100"
                                                      src={logoUrl}
                                                    />
                                                  </figure>
                                                </div>
                                              </div>) :
                                              (
                                                <div>
                                                  <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                                  <span className="js-fileName">Selecione uma imagem</span>
                                                </div>
                                              )
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              )}
                            </ Dropzone>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12 mb-3">
                        <textarea
                          {...register("conteudo")}
                          className={'h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg' + `${errors.conteudo ? " inputError" : ''}`}
                          cols="30"
                          rows="10"
                          placeholder="O que você quer compartilhar?"
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 ">
                      <input
                        className="bg-gradiant-dark-green text-center text-white font-xsss fw-600 p-3 border-0 w175 rounded-3 d-inline-block"
                        type="submit"
                        value={'Publicar'}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FeedAdd;
