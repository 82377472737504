import { steps } from '../reducers/steps';
import { successNotification, errorNotification } from './communication';
import history from '../../utils/history';
import * as data from '../../data/requests';

export const { updateStep, updateCurrentStep, updateStepTypes, updateCurrentConfigTypeStep } = steps.actions;

export const getStepsById = id => async dispatch => {
  try {
    const response = await data.get(`fase/${id}`);
    if (response.status === 200) {
      dispatch(updateCurrentStep(response.data.data));
      return response.data.data;
    }
  } catch (error) {
    console.error('Não é possível retornar fase.');
  }
};

export const getStepsByRoomId = idSala => async dispatch => {
  try {
    const response = await data.get(`sala/${idSala}/fases`);
    if (response.status === 200) {
      dispatch(updateStep(response.data.data));
      return response.data.data;
    }
  } catch (error) {
    console.error('Não é possível retornar fases da sala.');
  }
};

export const getStepsTypes = () => async dispatch => {
  try {
    const response = await data.get(`tipo/fase`);
    if (response.status === 200) {
      dispatch(updateStepTypes(response.data));
      return response.data.map(type => ({
        value: type.id_tipo_fase,
        label: type.titulo_tipo_fase,
      }));
    }
  } catch (error) {
    console.error('Não é possível tipos de fases da sala.');
  }
};

export const addStep = (params, id) => async dispatch => {
  try {
    const response = await data.post('fase', params);
    if (response.status === 201) {
      dispatch(successNotification('Nova fase adicionada com sucesso!'));
      history.push(`/admin/salas/editar/${id}?tab=fase`);
    }
  } catch (error) {
    console.error('Não foi possível adicionar a fase.');
  }
};

export const editStep = (params, roomId, stepId) => async dispatch => {
  try {
    const response = await data.postForm(`fase/${stepId}`, params);
    if (response.status === 201) {
      dispatch(successNotification('Fase editada com sucesso!'));
    }
  } catch (error) {
    console.error('Não foi possível adicionar a fase.');
  }
};

export const deleteStep = (id, idRoom) => async dispatch => {
  try {
    const response = await data.del(`fase/${id}`);
    if (response.status === 204) {
      dispatch(getStepsByRoomId(idRoom));
      dispatch(successNotification('Etapa excluída com sucesso'));
    }
  } catch (error) {
    console.error('Não foi possível deletar a fase.');
  }
};

export const changeOrder =
  (currentPos, targetPos, roomId, stepId) => async dispatch => {
    try {
      await data.post(`fase/${stepId}/atualizarOrdem`, {
        posicaoAtual: currentPos,
        posicaoAlvo: targetPos,
        idSala: roomId,
      });
      await dispatch(getStepsByRoomId(roomId));
    } catch (error) {
      dispatch(errorNotification('Não foi possível trocar a ordem das fases.'));
    }
  };

export const checkinStep = idFase => async dispatch => {
  try {
    const response = await data.post(`fase/${idFase}/checkin`);
    if (response.status === 201) {
      return true;
    }
  } catch (error) {
    console.error('Não é possível realizar Checkin nessa Fase.');
  }
};

export const checkoutStep = idFase => async dispatch => {
  try {
    const response = await data.post(`fase/${idFase}/checkout`);
    if (response.status === 201) {
      return true;
    }
  } catch (error) {
    console.error(error.data.message);
  }
};
{/* ----------------------------- CONFIGURAÇÃO DE UMA FASE ----------------------------- */}
export const getConfigTypeStep = idTypeStep => async dispatch => {
  try {
    const response = await data.get(`configuracao/tipoFase/${idTypeStep}`);
    if (response.status === 200) {
      dispatch(updateCurrentConfigTypeStep(response.data.data));
      return true;
    }
  } catch (error) {
    dispatch(errorNotification('Não foi possível obter as configurações dessa fase.'));
  }
};
