import React,{Component} from 'react';

class Warning extends Component {
  render() {
    const {message} = this.props;
    return (
      <div className="card bg-greyblue border-0 p-4 mb-3">
        <p className="mb-0 mont-font font-xssss text-uppercase fw-600 text-grey-500">
          <i className="fa fa-exclamation-circle" /> {message}
        </p>
      </div>
    );
  }
}

export default Warning;


