import React, {useEffect} from 'react';
import { Link } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {fetchRooms, resetRoomList} from "../../../../redux/actions/rooms";
import InfiniteScroll from 'react-infinite-scroll-component';

const DataListRooms = ({ query = '' }) => {

  const dispatch = useDispatch();

  const rooms = useSelector((state) => state.rooms.content);
  const user = useSelector((state) => state.session.user);

  const { current_page, total_pages } = useSelector((state) => state.pagination.content);

  useEffect(() => {
    dispatch(resetRoomList());
    dispatch(fetchRooms({ ...query, page: 1 }));
  }, [dispatch, query])

  const fetchNextPage = (page) => {
    dispatch(fetchRooms({ ...query, page }));
  };

  return (
    
      <InfiniteScroll
        className={'overflow-hidden InfiniteScroll'}
        scrollableTarget="main-content"
        dataLength={rooms.length}
        next={() => fetchNextPage(current_page + 1)}
        hasMore={current_page < total_pages}
        endMessage={
          <p className="pt-4" style={{ textAlign: 'center' }}>
            <b>Fim da listagem</b>
          </p>
        }
      >
        <div className="row">
            {rooms.map((room, index) => (
              <div key={index} className="col-md-6 col-sm-12 pe-2 ps-2" id="gridSala">
                <div className="card w-100 border-0 mt-4 bg-grey">
                  <div className="card-image w-100 p-0 text-center bg-greylight rounded-3 mb-2">
                    <Link  to={`/sala/${room.slug}`}><img src={room.capa_url} alt="imagem da sala" className="w-100 mt-0 mb-0" /></Link>
                  </div>
                  <div className="card-body w-100 text-center title">
                    <h2 className="mt-2 mb-1 text-left"><Link to={`/sala/${room.slug}`}  className="text-black fw-700 font-xsss lh-26">{room.nome}</Link></h2>
                    <h6 className="font-xsss fw-600 text-grey-500 ls-2 text-left">{room.nome_categoria}</h6>
                  </div>
                  <Link to={`/sala/${room.slug}`} className="position-absolute bottom-15 mb-2 right-15">
                    <i className="btn-round-sm bg-primary-gradiant text-white font-sm feather-chevron-right" />
                  </Link>
                </div>
              </div>
            ))}
        </div>
      </InfiniteScroll>
    
  );
}

export default DataListRooms;
