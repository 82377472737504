import { session } from '../reducers/session';
import { successNotification, errorNotification } from './communication';
import * as data from '../../data/requests';
import history from '../../utils/history';
import { fetchLoggedUser } from './users';

export const { login, logout, updateToken } = session.actions;

export const authenticate = params => async dispatch => {
  try {
    const response = await data.post('auth/login', params);
    if (response.status === 201) {
      data.updateDefaultToken(response.data.token);
      await dispatch(updateToken(response.data.token));
      await dispatch(fetchLoggedUser());
      history.push('/home');
      dispatch(successNotification('Usuário autenticado com sucesso!'));
    }
  } catch (error) {
    console.error('Verifique seu e-mail/senha e tente novamente');
    dispatch(errorNotification('Erro de autenticação!'));
  }
};

export const registerNewUser = params => async dispatch => {
  try {
    const response = await data.post('usuario/registrar', params);
    if (response.status === 201) {
      history.push('/auth/login/'+params.email);
      dispatch(successNotification('Usuário cadastrado com sucesso!'));
    }
  } catch (error) {
    console.error('Erro ao cadastrar novo usuário');
  }
};

export const retrievePassword = params => async dispatch => {
  try {
    const response = await data.post('auth/recuperar-senha', params);
    if (response.status === 200) {
      history.push('/auth/confirmar');
    }
  } catch (error) {
    console.error('Verique o e-mail inserido');
    dispatch(errorNotification('Erro ao enviar e-mail de recuperação.'));
  }
};

export const changePassword = params => async dispatch => {
  try {
    const response = await data.post('auth/reset', params);
    if (response.status === 200) {
      history.push('/auth');
      dispatch(successNotification('Senha atualizada com sucesso!'));
    }
  } catch (error) {
    dispatch(
      errorNotification(
        'Erro ao atualizar a senha. Verifique os dados e tente novamente.',
      ),
    );
  }
};

export const userConfirmeRegistration = (email, token) => async dispatch => {
  try {
    const response = await data.post('email/verify/' + email + '/' + token);
    if (response.status === 201) {
      dispatch(successNotification('Cadastro validado com sucesso!'));
      history.push('/auth');
    }
  } catch (error) {
    console.error('Erro ao validar cadastro. Tente novamente.');
    history.push('/auth/validar-registro/?email=' + email + '&token=' + token);
  }
};

export const resendEmailValidadion = params => async dispatch => {
  try {
    const response = await data.get('/email/resend' + '?email=' + params.email);
    if (response.status === 200) {
      history.push('/auth');
      dispatch(successNotification('E-mail de validação enviado com sucesso.'));
    }
  } catch (error) {
    history.push('/auth/reenviar-email');
    dispatch(errorNotification('Verique o e-mail inserido.'));
    dispatch(errorNotification('Erro ao Reenviar e-mail de validação.'));
  }
};
