import React from 'react';
import {useSelector} from "react-redux";
import { Link } from 'react-router-dom';

import {ReactComponent as Livros} from "../../../../public/theme/livapp/images/app/icones/livro.svg";
import {ReactComponent as Clubes} from "../../../../public/theme/livapp/images/app/icones/clubes.svg";
import {ReactComponent as Salas} from "../../../../public/theme/livapp/images/app/icones/salas.svg";
import {ReactComponent as Home} from "../../../../public/theme/livapp/images/app/icones/home.svg";


const Appfooter = () => {
  
    const user = useSelector((state) => state.session.user);

    return (
      <div className="app-footer border-0 shadow-lg bg-gradiant-dark-green">
        <Link to="/home" className="nav-content-bttn open-font">
          <div className="iconeBottom">
            <Home />
          </div>
        </Link>
        <Link to="/salas" className="nav-content-bttn open-font">
          <div className="iconeBottom">
            <Salas />
          </div>
        </Link>
        <Link to="/clubes" className="nav-content-bttn open-font">
          <div className="iconeBottom">
            <Clubes />
          </div>
        </Link>
        <Link to="/livros" className="nav-content-bttn open-font">
          <div className="iconeBottom">
            <Livros />
          </div>
        </Link>
        <Link to="/config" className="nav-content-bttn">
          <img src={user?.avatar === null ? '/theme/livapp/images/app/default/user_avatar_padrao.png' : user?.avatar_url}  alt="user" className="w30 shadow-xss rounded-circle" />
        </Link>
      </div>
    );
  
}

export default Appfooter;
