import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

export default function DataListMembers ({members = [], type= ""}) {
  
  return (
    <>
      {members && members.map((value, index) => (
        <div key={index} className="col-md-3 col-sm-4 pe-2 ps-2">
          <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
            <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
              <figure className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w65 z-index-1">
                <img src={`${value.avatarUrl}`} alt="avater" className="float-right p-0 bg-white rounded-circle w-100 shadow-xss" />
              </figure>
              <div className="clearfix w-100" />
              <h4 className="fw-700 font-xsss mt-3 mb-0">{value.nome} </h4>
              {type && <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">{type}</p>}
              {'Membro' === type && <span className="mt-4 p-0 btn p-2 lh-24 w100 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white">Pontos <br />{value.pontos}</span> }
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

