import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  books: [],
  clubs: [],
  rooms: []
};

const reducers = {
  updateDataHome: (state, action) => {
    state.books = action.payload.livro;
    state.clubs = action.payload.clube;
    state.rooms = action.payload.sala;
  },
};

export const home = createSlice({
  name: 'home',
  initialState,
  reducers,
});
