import React from 'react';
import { useDispatch } from "react-redux";
import { followClub } from "../../../redux/actions/clubs";

export default function ButtonActionFollowClub({ idClub = 0, boolFollowClub = 0, slugRoom = '', style = ''}) {
  
  const dispatch = useDispatch();

  return (
    <div>
      {boolFollowClub ? (
        <button
          type="button"
          className={'border-0 bg-danger ' + `${style}`}
          title={"Parar de Seguir"}
          onClick={() => dispatch(followClub(idClub, false))}>
          <i className="feather-heart font-md text-white" />
        </button>
      ) : (
        <button
          type="button"
          className={'border-0 bg-sucess' + `${style}`}
          title={"Seguir"}
          onClick={() => dispatch(followClub(idClub, true))}>
          <i className="feather-heart font-md text-dark" />
        </button>
      )}
    </div>
  )
}
