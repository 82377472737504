import React from 'react';
import {checkinStep} from "../../../redux/actions/steps";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import history from "../../../utils/history";
import {deleteFeed} from "../../../redux/actions/feeds";

export default function ButtonActionCheckinStep ({idFeed = {}, idStep = {}, ...props}) {

  const dispatch = useDispatch();

  const onActionDeleteFeed = (idFeed) => {
      dispatch(deleteFeed(idFeed, idStep))
  }
  
  return (
      <div className="ms-auto pointer"><i className="ti-trash text-grey-900 btn-round-md bg-greylight font-xss" onClick={() => onActionDeleteFeed(idFeed)}/></div>
  )
}
