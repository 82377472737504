/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  content: [],
};

const reducers = {
  updateFeeds: (state, action) => {
    state.content = action.payload;
  },
};

export const feeds = createSlice({
  name: 'feeds',
  initialState,
  reducers,
});
