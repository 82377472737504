import React from 'react';
import { Link } from 'react-router-dom';

export default function ButtonEdit({ url = '' }) {

  return (
    <>
      <Link title="Editar" to={`${url}`} className="d-lg-block bg-greylight btn-round-lg ms-2 rounded-3 text-grey-700">
        <i className="feather-edit font-md" />
      </Link>
    </>
  )
}


