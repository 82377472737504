import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone';

import { fetchAuthorById, editAuthor, saveAuthor } from '../../../../redux/actions/authors';
import Header2 from "../../../layout/header/Header";

const AuthorForm = () => {
  const dispatch = useDispatch();

  let { id } = useParams();
  let location = useLocation();

  const [logoUrl, setLogoUrl] = useState(undefined);
  const [image, setImage] = useState(undefined);

  const isEditMode = location.pathname.includes('editar');

  const {
    register,
    handleSubmit,
    reset,
  } = useForm();

  useEffect(() => {
    async function getData() {
      if (isEditMode) {
        let author = await dispatch(fetchAuthorById(id));

        const {
          imagem_url,
          nome,
          pseudonimo,
          descricao,
        } = author;

        setLogoUrl(imagem_url);

        reset({
          imagem_url,
          nome,
          pseudonimo,
          descricao
        });
      }
    }

    getData();
  }, []);

  const onChangeImage = (file) => {
    const reader = new FileReader();
    setImage(file);

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setLogoUrl(reader.result);
    }
  }

  const onSubmit = (form) => {
    const {
      nome,
      pseudonimo,
      descricao,
    } = form;

    const formData = new FormData();

    if (image) {
      formData.append('imagePrincipal', image, image.name);
    }

    if (nome) {
      formData.append('nome', nome);
    }

    if (pseudonimo) {
      formData.append('pseudonimo', pseudonimo);
    }

    if (descricao) {
      formData.append('descricao', descricao);
    }

    if (isEditMode) {
      dispatch(editAuthor(id, formData));
    } else {
      dispatch(saveAuthor(formData));
    }
  };

  return (
    <>
      <Header2 title={isEditMode ? 'Editar Autor' : 'Cadastrar Autor'} />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom-custom">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-xl-12">
                <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="card-body d-flex justify-content-between align-items-end p-0">
                          <div className="form-group mb-0 w-100">
                            <label className="mont-fnt fw-600 font-xsss mb-2">Capa *</label>
                            <Dropzone onDrop={files => onChangeImage(files[0])}>
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div {...getRootProps()}>
                                    <div className="col-lg-12 mb-3">
                                      <div className="card mt-3 border-0">
                                        <input
                                          {...getInputProps()}
                                          name="logo"
                                          type="file"
                                          accept="image/png, image/gif, image/jpeg"
                                          onChange={(event) => onChangeImage(event.target.files[0])}
                                          className="form-control"
                                        />
                                        <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">

                                          {
                                            logoUrl ?
                                              (<div className="row justify-content-center">
                                                <div className="col-lg-4 text-center">
                                                  <figure className="avatar ms-auto me-auto mb-0 mt-2 w100">
                                                    <img
                                                      alt="imagem-autor"
                                                      className="shadow-sm rounded-3 w-100"
                                                      src={logoUrl}
                                                    />
                                                  </figure>
                                                </div>
                                              </div>) :
                                              (
                                                <div>
                                                  <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                                  <span className="js-fileName">Arraste e solte ou clique para trocar</span>
                                                </div>
                                              )
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              )}
                            </ Dropzone>
                            <div className="col-lg-12 mt-3">
                              <div
                                onClick={() => {
                                  setLogoUrl(undefined);
                                  setImage(undefined);
                                }}
                                className="bg-gradiant-dark-green text-center text-white font-xsss fw-600 p-1 border-0 w175 rounded-3 d-inline-block"
                              >
                                Remover Imagem
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">Nome *</label>
                          <input {...register("nome", { required: true })}
                                 type="text"
                                 className="form-control"
                                 placeholder="Nome..."
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">Pseudonimo</label>
                          <input {...register("pseudonimo", { required: true })}
                                 type="text"
                                 className="form-control"
                                 placeholder="Nome..."
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 mb-3">
                        <label className="mont-font fw-600 font-xsss mb-2 text-dark">Descrição</label>
                        <textarea {...register("descricao")} className="form-control mb-0 p-3 h100 bg-greylight lh-16" rows="5" placeholder="Escreva sobre o autor..." ></textarea>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <input
                        className="bg-gradiant-dark-green text-center text-white font-xsss fw-600 p-3 border-0 w175 rounded-3 d-inline-block"
                        type="submit"
                        value={isEditMode ? "Editar" : "Salvar"}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AuthorForm;
