import React from 'react';
import {useSelector} from "react-redux";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import Header from "../../../layout/header/Header";
import ButtonActionParticipate from "../../../components/rooms/ButtonActionParticipate";
import ButtonActionLikeRoom from "../../../components/rooms/ButtonActionLikeRoom";
import ButtonEdit from "../../../components/App/Actions/ButtonEdit";
import DetailedInformation from "./parts/DetailedInformation";
import ListBooks from "../../../app/components/books/ListBooks";
import ListSteps from '../../../app/components/step/ListSteps';

export default function RoomsDetailWithoutMember() {

  const room = useSelector((state) => state.rooms.current);
  const user = useSelector((state) => state.session.user);

  const isAdmin = room.usuario === user.id;

  function openWhatsapp(link) {
    window.open(link);
  }

  return (
    <>
      <Tabs defaultIndex={0} id="TabRoomDetail" className="">
        
        <Header backPage={'/salas'} titlePage={"Detalhes da Sala"} />

        <div className={`hero_in detail_page background-image`} style={{ backgroundImage: `url("${room.capa_url}")` }}>
          <div className="wrapper opacity-mask" style={{ backgroundColor: `rgba(0, 0, 0, 0.5)` }}>
            <div className="container">
              <div className="main_info">
                <div className="">
                  <div className="head">
                    <h1 className="">{room.nome}</h1>
                  </div>
                  <div className="d-flex pt-0">
                    {false === isAdmin ?
                      (
                        <>
                          <ButtonActionParticipate
                            participate={room.participante}
                            idSala={room.id}
                            slugSala={room.slug}
                            style="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3 border-0"
                          />
                          <ButtonActionLikeRoom
                            idRoom={room.id}
                            slugRoom={room.slug}
                            boolFollow={room.curtida}
                            style="bbg-greylight btn-round-lg ms-2 rounded-3 text-grey-700"
                          />
                        </>
                      ) : (
                        <ButtonEdit url={`/admin/salas/editar/${room.id}`} />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <TabList className="" id="pills-tab" role="tablist">
            <Tab className="col-4 text-center fw-700 font-xssss pt-3 pr-20 pl-20 ls-1 d-inline-block text-dark pb-1">FASES</Tab>
            <Tab className="col-4 text-center fw-700 font-xssss pt-3 pr-20 pl-20 ls-1 d-inline-block text-dark pb-1">DETALHES</Tab>
            <Tab className="col-4 text-center fw-700 font-xssss pt-3 pr-20 pl-20 ls-1 d-inline-block text-dark pb-1">LIVROS</Tab>
          </TabList>
        </div>
        <div className="divider" />

        <div className="" id="groupTabs">
          <TabPanel id="tabFases">
            <div className="card w-100 border-0 shadow-none bg-transparent mt-2">
              <ListSteps
                steps={room.fases}
                participate={room.participante}
                slugSala={room.slug}
                isAdmin={isAdmin}
              />
            </div>
          </TabPanel>
          <TabPanel>
            <DetailedInformation />
          </TabPanel>
          <TabPanel>
            <ListBooks books={room.livros} />
          </TabPanel>
        </div>
      </Tabs>
    </>
  )
}
