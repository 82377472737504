import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFeeds } from '../../../../redux/actions/feeds';
import ListFeeds from "../../../components/Feeds/ListFeeds";
import { useParams } from "react-router-dom";
import ButtonInsert from "../../../components/App/Actions/ButtonInsert";
import Header from "../../../layout/header/Header";

const FeedsIndex = () => {

  const dispatch = useDispatch();
  const data = useSelector((state) => state.feeds.content);

  let { idStep } = useParams();

  useEffect(() => {
    dispatch(fetchFeeds(idStep))
  }, [dispatch])

  return (
    <>
      <Header backPage={`/sala/${data.sala?.slug}`} titlePage={`Feeds`} />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row">
              <ListFeeds
                rooms={data.sala}
                feeds={data.feeds}
                idStep={idStep}
              />
              <ButtonInsert linkInsert={`/admin/salas/fases/${idStep}`} linkHome={"/sala/" + data.sala?.slug} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FeedsIndex;
