import { rooms } from '../reducers/rooms';
import { errorNotification, successNotification } from './communication';
import { updatePagination } from './pagination';
import * as data from '../../data/requests';
import history from '../../utils/history';
import { Redirect } from 'react-router-dom';

export const {
  updateRooms,
  updateCurrentRoom,
  updateMembersCurrentRoom,
  updateRoomsPaginated,
  updateRoomCategory,
  resetRooms,
} = rooms.actions;

export const fetchRooms =
  (query = {}) =>
  async dispatch => {
    const queryParams = '?' + new URLSearchParams(query);

    try {
      const response = await data.get(`sala${queryParams}`);

      if (response.status === 200) {
        dispatch(updateRooms(response.data.data));
        dispatch(updatePagination(response.data.meta.pagination));
      }
    } catch (error) {
      console.error('Erro ao retornar salas paginados!');
    }
  };

export const searchRooms = query => async dispatch => {
  try {
    const response = await data.get(`/sala?q=${query}`);
    if (response.status === 200) {
      dispatch(updateRooms(response.data.data));
    }
  } catch (error) {
    console.error('Erro ao procurar salas cadastradas!');
  }
};

export const fetchClubRooms = idClub => async dispatch => {
  try {
    const response = await data.get(`/sala?q=&clube=` + idClub);
    if (response.status === 200) {
      dispatch(updateRooms(response.data.data));
    }
  } catch (error) {
    console.error('Erro ao procurar Salas!');
  }
};

export const fetchFollowedRooms = () => async dispatch => {
  try {
    const response = await data.get('sala/usuario/logado');
    if (response.status === 200) {
      dispatch(updateRooms(response.data));
    }
  } catch (error) {
    console.error('Erro ao retornar lista de salas criadas!');
  }
};

export const fetchCreatedRooms = () => async dispatch => {
  try {
    const response = await data.get('sala/usuario/logado');
    if (response.status === 200) {
      dispatch(updateRooms(response.data));
    }
  } catch (error) {
    console.error('Erro ao retornar lista de salas criadas!');
  }
};

export const fetchRoom = id => async dispatch => {
  try {
    const response = await data.get(`sala/${id}`);
    if (response.status === 200) {
      dispatch(updateCurrentRoom(response.data.data));
    }
  } catch (error) {
    dispatch(
      errorNotification('Erro ao retornar informação da sala selecionada!'),
    );
    history.push('/salas');
  }
};

export const exitRoom = (idSala, SlugSala) => async dispatch => {
  try {
    const response = await data.del(`sala/${idSala}/sair`);
    if (response.status === 204) {
      dispatch(fetchRoom(SlugSala));
    }
  } catch (error) {
    console.error('Erro ao sair da sala selecionada.');
  }
};

export const participateRoom = (idSala, SlugSala) => async dispatch => {
  try {
    const response = await data.post(`sala/${idSala}/participar`);
    if (response.status === 201) {
      dispatch(fetchRoom(SlugSala));
    }
  } catch (error) {
    console.error('Erro ao Participar da sala selecionada.');
  }
};

export const fetchMembersRoom = (idRoom, params) => async dispatch => {
  try {
    const response = await data.get(
      `sala/${idRoom}/membros?tipoRanking=` + params,
    );
    if (response.status === 200) {
      dispatch(updateMembersCurrentRoom(response.data.data));
    }
  } catch (error) {
    console.error('Erro ao retornar membros da S!');
  }
};

export const likeRoom = (idRoom, SlugRoom, boolLike) => async dispatch => {
  try {
    const response = await data.post(`sala/${idRoom}/curtir`, {
      curtir: boolLike,
    });

    if (response.status === 201) {
      if (boolLike) {
        dispatch(successNotification('Sala Curtida com Sucesso!'));
      } else {
        dispatch(successNotification('Você não curte mais essa Sala!'));
      }
      await dispatch(fetchRoom(SlugRoom));
    }
  } catch (error) {
    console.error(error.data?.message);
  }
};

export const fetchRoomsCategory = () => async dispatch => {
  try {
    const response = await data.get('categorias/sala');
    if (response.status === 200) {
      dispatch(
        updateRoomCategory(
          response.data.map(category => {
            return {
              value: category.id_categoria_sala,
              label: category.nome_categoria,
            };
          }),
        ),
      );
    }
  } catch (error) {
    console.error('Erro ao retornar categorias da sala!');
  }
};

export const saveRoom = formData => async dispatch => {
  try {
    const response = await data.post('/sala', formData);
    if (response.status === 201) {
      dispatch(successNotification('Sala criada com Sucesso!'));
      dispatch(updateCurrentRoom(response.data.data));
      if (response.headers.location) {
        history.push(response.headers.location);
      } else {
        history.push('/admin/salas/adicionar');
      }
    }
  } catch (error) {
    console.error('Erro ao salvar sala!');
  }
};

export const editRoom = (formData, id) => async dispatch => {
  try {
    const response = await data.postForm(`/sala/${id}`, formData);
    if (response.status === 201) {
      dispatch(updateCurrentRoom(response.data.data));
      dispatch(fetchRoom(id));
      dispatch(successNotification('Sala editada com sucesso!'));
    }
  } catch (error) {
    console.error('Erro ao editar sala!');
  }
};

export const resetRoomList = () => dispatch => {
  dispatch(resetRooms());
};
