/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  listPublishersAdmin: [],
};

const reducers = {
  updatePublishersAdmin: (state, action) => {
    state.listPublishersAdmin = action.payload;
  },
};

export const publishersAdm = createSlice({
  name: 'publishersAdm',
  initialState,
  reducers,
});
