import React from 'react';
import { toast, Toaster, ToastBar } from 'react-hot-toast';

const ToasterCustom = () => {
  return (
    <Toaster>
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {message}
              <div onClick={() => toast.dismiss(t.id)}>
                {icon}
              </div>
            </>
          )}
        </ToastBar>
      )}
    </ Toaster>
  );
}

export default ToasterCustom;
