import React from 'react';
import {Link} from "react-router-dom";
import {ReactComponent as Avatar} from "../../../../../public/assets/images/icones/avatar.svg";

const ClubsAdminIndex = () => {

  return (
    <>
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom-submenu">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-xl-12">
                <div className="card-body p-lg-5 p-4 w-100 border-0">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="nav-caption fw-600 font-xssss text-grey-500 mb-2">Gerenciamento</div>
                      <ul className="list-inline mb-4">
                        <li className="list-inline-item d-block border-bottom me-0">
                          <Link to="/admin/clubes/adicionar" className="pt-2 pb-2 d-flex align-items-center">
                            <div className="btn-round-md bg-primary-dark-green me-3">
                              <i className="feather-plus font-md text-grey-500 ms-auto mt-3" />
                            </div>
                            <h4 className="fw-600 font-xsss mb-0 mt-0">Adicionar</h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3" />
                          </Link>
                        </li>
                        <li className="list-inline-item d-block border-bottom me-0">
                          <Link to="/config/avatar" className="pt-2 pb-2 d-flex align-items-center">
                            <div className="btn-round-md bg-primary-dark-green me-3">
                              <i className="feather-list font-md text-grey-500 ms-auto mt-3" />
                            </div>
                            <h4 className="fw-600 font-xsss mb-0 mt-0">Criados por Mim</h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ClubsAdminIndex;
