import React from 'react';
import Header2 from "../../layout/header/Header";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import BooksAll from "./list/BooksAll";
import BooksSpotlight from "./list/BooksSpotlight";
import ButtonInsert from "../../components/App/Actions/ButtonInsert";

const BooksMain = () => {
  return (
    <>
      <Header2 title={"Livros"} />
      <ButtonInsert linkInsert={"/admin/livros/adicionar"} />
      <Tabs defaultIndex={0} id="TabRoom" className="submenu nav nav-fill nav-default">
        <TabList className="nav nav-tabs h55 d-flex border-bottom-1 w-100" id="pills-tab" role="tablist">
          <Tab className="col-6 text-center"><span className="fw-700 font-xssss link-tab pt-3 pr-20 pl-20 ls-1 d-inline-block">DESTAQUE</span></Tab>
          <Tab className="col-6 text-center"><span className="fw-700 font-xssss link-tab pt-3 pr-20 pl-20 ls-1 d-inline-block">PESQUISA</span></Tab>
        </TabList>
          <TabPanel className="w-100"><BooksSpotlight /></TabPanel>
          <TabPanel className="w-100"><BooksAll /></TabPanel>
      </Tabs>
    </>
  );
}

export default BooksMain;
