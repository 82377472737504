import React, {Component, useEffect} from 'react';
import Slider from "react-slick";
import { Link } from "react-router-dom";

class HomeBooks extends Component {
  render() {
    const storysettings = {
      arrows: false,
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: 1,
      centerMode: false,
      variableWidth: true
    };

    const books = this.props.books;

    return (
      <>
        <div className="card-body d-flex align-items-center">
          <h4 className="fw-700 mb-0 font-xssss text-grey-900">Livros</h4>
          <a href="/home" className="fw-600 ms-auto font-xssss text-primary">Ver todos</a>
        </div>
        <Slider key={'slideBooks'} {...storysettings}>
          {books.map((book, index) => (
            <div key={index}>
              <Link to={`livro/${book.slug}`}>
                <div className="card_livro card w125 h200 d-block border-0 shadow-xss rounded-xxxl bg-gradiant-bottom overflow-hidden cursor-pointer mb-3 mt-0 me-3" style={{ backgroundImage: `url("${book.imagemUrl}")`}}>
                  <div className="card-body d-block p-3 w-100 position-absolute bottom-0 text-center" >
                    <div className="clearfix mt-1" />
                    <h4 className="fw-600 position-relative z-index-1 ls-1 font-xssss text-white mt-2 mb-1">{book.nome} </h4>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      </>
    );
  }
}

export default HomeBooks;
