import React from 'react';
import {exitRoom, participateRoom} from "../../../redux/actions/rooms";
import {useDispatch} from "react-redux";

export default function ButtonActionParticipate ({participate = {}, idSala = {}, slugSala, style = {}, ...props}) {

  const dispatch = useDispatch();

  const onExitOrParticipateRoom = (participate, idSala) => {
    if (participate) {
      dispatch(exitRoom(idSala, slugSala));
    }else{
      dispatch(participateRoom(idSala, slugSala));
    }
  };

  return (
    <div>
      {participate ? (
          <button type="button" className={style}  onClick={() => onExitOrParticipateRoom(participate, idSala)}>SAIR</button>
      ) : (
        <button type="button" className={style}  onClick={() => onExitOrParticipateRoom(participate, idSala)}>PARTICIPAR</button>
      )}
    </div>
  )
}
