import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

const ListAuthors = ({ authors, fetchNextPage }) => {
  
  const { current_page, total_pages } = useSelector((state) => state.pagination.content);

  return (
    <InfiniteScroll
      className={"overflow-hidden"}
      dataLength={authors.length}
      next={() => fetchNextPage(current_page + 1)}
      hasMore={current_page < total_pages}
      endMessage={
        <span style={{ textAlign: 'center' }}>
          <b>Fim da listagem</b>
        </span>
      }
    >
      <div className="row ps-2 pe-1 items-grid">
        {authors && authors.map((author, index) => (
          <div key={index} className="col-md-4 col-sm-4 pe-2 ps-2">
            <div className="ui-block border-0">
              <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                  <figure className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w65 z-index-1">
                    <img src={author.imagem_url || 'assets/images/author-temporary.jpg'} alt="avatar" className="float-right p-0 bg-white rounded-circle w-100 shadow-xss" />
                  </figure>
                  <div className="clearfix w-100"></div>
                  <h4 className="fw-700 font-xsss mt-3 mb-3">{author.pseudonimo} </h4>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      
    </InfiniteScroll>
  );
}

export default ListAuthors;
