import React from 'react';
import { Switch, Route } from 'react-router-dom';

/* ------------------------------- Components ------------------------------- */

import Home from './pages/home/Home';
import NotFound from './pages/not-found/NotFound';

import Login from './pages/authentication/login/Login';
import Register from './pages/authentication/register/Register';
import SuccessfullyRegistered from './pages/authentication/register/SuccessfullyRegistered';
import PasswordChange from './pages/authentication/password-change/PasswordChange';
import PasswordForgotten from './pages/authentication/password-forgotten/PasswordForgotten';
import PasswordConfirmation from './pages/authentication/password-confirmation/PasswordConfirmation';
import ValidateRegistration from './pages/authentication/confirm-registration/ValidateRegistration';

import PrivateRoutes from './components/private-routes/PrivateRoutes';

import ClubsMain from './pages/clubs/ClubsMain';
import ClubsEdit from './pages/clubs/edit/ClubsEdit';
import ClubsAdd from './pages/clubs/add/ClubsAdd';
import ClubsDetail from './pages/clubs/detail/ClubsDetail';

import RoomsMain from './pages/rooms/RoomsMain';
import RoomsDetail from './pages/rooms/detail/RoomsDetail';
import RoomsAdd from './pages/rooms/add/RoomsAdd';
import FeedAdd from './pages/feeds/adm/FeedAdd';
import StepAdd from './pages/step/add/StepAdd';
import StepEdit from './pages/step/edit/StepEdit';

/* Fluxo: Adicionar Fase na Sala */
import StepChooseType from './pages/step/flow/StepChooseType';
import StepDetails from './pages/step/flow/StepDetails';

import BooksMain from './pages/books/BooksMain';
import BooksForm from './pages/books/form/BookForm';
import BooksDetail from './pages/books/detail/BooksDetail';
import BooksCreatedByMe from './pages/books/list/BooksCreatedByMe';

import AuthorsList from './pages/authors/list/AuthorsList';
import AuthorForm from './pages/authors/form/AuthorForm';
import AuthorDetail from './pages/authors/detail/AuthorDetail';
import AuthorAdminIndex from "./pages/authors/adm/AuthorsAdminIndex";
import PublishersList from './pages/publishers/list/PublishersList';
import PublishersAdd from './pages/publishers/add/PublishersAdd';
import PublishersEdit from './pages/publishers/edit/PublishersEdit';
import PublishersDetail from './pages/publishers/detail/PublishersDetail';
import Configuration from './pages/configuration/Configuration';
import ProfileEdit from './pages/configuration/ProfileEdit';
import AvatarEdit from './pages/configuration/AvatarEdit';
import PasswordEdit from './pages/configuration/PasswordEdit';
import EmailEdit from './pages/configuration/EmailEdit';

import FeedsIndex from "./pages/feeds/index/FeedsIndex";
import ClubsAdminIndex from "./pages/clubs/adm/ClubsAdminIndex";

import PublishersAdminIndex from "./pages/publishers/adm/PublishersAdminIndex";
import ConfirmRegistrationHandle from "./pages/authentication/confirm-registration/ConfirmRegistrationHandle";
import ResendValidadionEmail from "./pages/authentication/resend-validation/ResendValidationEmail";
import Logout from "./pages/configuration/Logout";

import StepsByRoom from "./pages/step/list/StepsByRoom";

/* --------------------------------- Routes --------------------------------- */

function Routes() {
  return (
    <Switch>
      {/* ------------------------------ Rotas Abertas ----------------------------- */}
      <Route exact path="/auth" component={Login} />
      <Route exact path="/auth/sair" component={Logout} />
      <Route exact path="/auth/registrar" component={Register} />
      <Route exact path="/auth/registrado-sucesso/:email" component={SuccessfullyRegistered} />
      <Route exact path="/auth/mudar" component={PasswordChange} />
      <Route exact path="/auth/recuperar" component={PasswordForgotten} />
      <Route exact path="/auth/confirmar" component={PasswordConfirmation} />
      <Route exact path="/auth/validar-registro" component={ValidateRegistration} />
      <Route exact path="/auth/confirmar-registro/:emailUser/:token" component={ConfirmRegistrationHandle} />
      <Route exact path="/auth/reenviar-email" component={ResendValidadionEmail} />
      {/* ----------------------------- Rotas Privadas ----------------------------- */}
      <PrivateRoutes>
        <Route exact path="/" component={Home} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/clubes" component={ClubsMain} />
        <Route exact path="/clubes/editar/:id" component={ClubsAdd} />
        <Route exact path="/clube/:id" component={ClubsDetail} />
        <Route exact path="/salas" component={RoomsMain} />
        <Route exact path="/salas/:tab" component={RoomsMain} />
        <Route exact path="/sala/:id" component={RoomsDetail} />
        <Route exact path="/fases/:idRoom" component={StepsByRoom} />
        <Route exact path="/fase/:idStep" component={FeedsIndex} />
        <Route exact path="/livros" component={BooksMain} />
        <Route exact path="/livro/:id" component={BooksDetail} />
        <Route exact path="/autores" component={AuthorsList} />
        <Route exact path="/autor/:id" component={AuthorDetail} />
        <Route exact path="/editoras" component={PublishersList} />
        <Route exact path="/editora/:id" component={PublishersDetail} />
        <Route exact path="/config" component={Configuration} />
        <Route exact path="/config/profile" component={ProfileEdit} />
        <Route exact path="/config/avatar" component={AvatarEdit} />
        <Route exact path="/config/password" component={PasswordEdit} />
        <Route exact path="/config/email" component={EmailEdit} />
        {/* ----------------------------- Rotas ADM ----------------------------- */}
        <Route exact path="/admin/clubes" component={ClubsAdminIndex} />
        <Route exact path="/admin/clubes/adicionar" component={ClubsAdd} />
        <Route exact path="/admin/clubes/editar/:id" component={ClubsEdit} />

        <Route exact path="/admin/livros/criados-por-mim" component={BooksCreatedByMe} />
        <Route exact path="/admin/livros/adicionar" component={BooksForm} />
        <Route exact path="/admin/livros/editar/:id" component={BooksForm} />

        <Route exact path="/admin/autores" component={AuthorAdminIndex} />
        <Route exact path="/admin/autores/adicionar" component={AuthorForm} />
        <Route exact path="/admin/autores/editar/:id" component={AuthorForm} />

        <Route exact path="/admin/editoras" component={PublishersAdminIndex} />
        <Route exact path="/admin/editoras/adicionar" component={PublishersAdd} />
        <Route exact path="/admin/editoras/editar/:id" component={PublishersEdit} />

        <Route exact path="/admin/salas/adicionar" component={RoomsAdd} />
        <Route exact path="/admin/salas/editar/:id" component={RoomsAdd} />
        <Route exact path="/admin/salas/editar/:id/adicionar-fase" component={StepAdd} />
        <Route exact path="/admin/salas/editar/:roomId/editar-fase/:stepId" component={StepEdit} />
        <Route exact path="/admin/salas/fases/:id" component={FeedAdd} />

        {/* ----------------------------- ADMIN - FASES ----------------------------- */}
        <Route exact path="/admin/fases/criar/escolher-tipo-fase" component={StepChooseType} />
        <Route exact path="/admin/fases/criar/detalhes-fase" component={StepDetails} />
        
      </PrivateRoutes>
      <Route path="*" component={NotFound} />
    </Switch>
  );
}

export default Routes;
