import { home } from '../reducers/home';
import { errorNotification } from './communication';
import * as data from '../../data/requests';

export const { updateDataHome } = home.actions;

export const fetchHome = () => async dispatch => {
  try {
    const response = await data.get('home');
    if (response.status === 200) {
      dispatch(updateDataHome(response.data.data));
    }
  } catch (error) {
    dispatch(errorNotification('Dados iniciais não encontrados!'));
  }
};

