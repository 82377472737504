import React, {useEffect, useState} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {fetchClub} from "../../../../redux/actions/clubs";
import DetailPageHeader from "../../../components/App/DetailHeader/DetailPageHeader";
import DataListRooms from "../../../components/App/DataList/DataListRooms";
import DataListMembers from "../../../components/App/DataList/DataListMembers";
import ButtonActionFollowClub from "../../../components/clubs/ButtonActionFollowClub";
import Header2 from "../../../layout/header/Header";
import NotResult from "../../../components/App/Message/NotResult";
import Header from "../../../layout/header/Header";

const ClubsDetail = () => {

  const dispatch  = useDispatch();
  
  const userLogged = useSelector((state) => state.session.user);
  const membersClub = useSelector((state) => state.clubs.memberCurrentClub);
  const club = useSelector((state) => state.clubs.current);

  let { id } = useParams();

  useEffect(() => {
    dispatch(fetchClub(id));
  }, [dispatch]);

  return (
    <>
      <Tabs defaultIndex={0} id="TabClub" className="mb-3">
        <Header backPage={`/clubes`} titlePage={`Detalhes do Clube`} />
        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card w-100 shadow-xss rounded-xxl overflow-hidden border-0 mb-3 mt-3 pb-3">
                    <DetailPageHeader background={club.capa_url} profileName={club.nome} />
                    <div className="card-body d-flex align-items-center justify-content-center ps-4 pe-4 pt-0">
                      {club.usuario !== userLogged.id &&
                      <ButtonActionFollowClub
                        idClub={id}
                        boolFollowClub={club.seguindo}
                        style="bbg-greylight btn-round-lg ms-2 rounded-3 text-grey-700"
                      />
                      }
                    </div>
                    <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
                      <TabList className="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4" id="pills-tab" role="tablist">
                        <Tab className="active list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block">Salas</a></Tab>
                        <Tab className="active list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block">Detalhes</a></Tab>
                        <Tab className="active list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block">Membros</a></Tab>
                      </TabList>
                    </div>
                  </div>
                </div>
                <div className="" id="groupTabs">
                  <div className="col-xl-12 col-xxl-12 col-lg-12">
                    <TabPanel id="tabRooms">
                      <div className="card w-100 border-0 shadow-none bg-transparent mt-2">
                        <DataListRooms query={ {clube : club.id_clube}} />
                      </div>
                    </TabPanel>
                    <TabPanel id="tabDetalhes">
                      <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 w100">
                        <div className="card-body d-block p-4">
                          <h4 className="fw-700 mb-3 font-xsss text-grey-900">Sobre o Clube</h4>
                          <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">{club.descricao}</p>
                        </div>
                        <div className="card-body border-top-xs d-flex">
                          <i className="feather-calendar text-grey-500 me-3 font-lg"></i>
                          <h4 className="fw-700 text-grey-900 font-xssss mt-0">Criado em: <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"> {club.criado_em}</span></h4>
                        </div>
                        <div className="card-body d-flex pt-0">
                          <i className="feather-user text-grey-500 me-3 font-lg"></i>
                          <h4 className="fw-700 text-grey-900 font-xssss mt-0">Responsável <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"> {club.nome_usuario}</span></h4>
                        </div>
                        <div className="card-body d-flex pt-0">
                          <i className="feather-list text-grey-500 me-3 font-lg"></i>
                          <h4 className="fw-700 text-grey-900 font-xssss mt-0">Categoria <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{club.categoria?.nome}</span></h4>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel id="tabMembros">
                      <div className="card w-100 border-0 shadow-none bg-transparent mt-2">
                        <div className="row ps-2 pe-1">
                          { membersClub.mediador && <DataListMembers members={membersClub.mediador} type={"Mediador"} /> }
                          { membersClub.membro && <DataListMembers members={membersClub.membro} type={"Membro"} /> }
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Tabs>
    </>
  );
}

export default ClubsDetail;
