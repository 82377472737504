/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  content: [],
  current: {},
  currentTypeStep: {},
  options: [],
};

const reducers = {
  updateStep: (state, action) => {
    state.content = action.payload;
  },
  updateCurrentStep: (state, action) => {
    state.current = action.payload;
  },
  updateStepTypes: (state, action) => {
    state.options = action.payload.map(type => ({
      value: type.id_tipo_fase,
      label: type.titulo_tipo_fase,
      description: type.descricao_tipo_fase,
    }));
  },
  updateCurrentConfigTypeStep: (state, action) => {
    state.currentTypeStep = action.payload;
  },
};

export const steps = createSlice({
  name: 'steps',
  initialState,
  reducers,
});
