import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {fetchClubsPaginated, resetClubsList} from "../../../../redux/actions/clubs";
import InfiniteScroll from "react-infinite-scroll-component";

export default function DataListClubs ({ query = '' }) {
  
  const user = useSelector((state) => state.session.user);

  const dispatch = useDispatch();
  const clubs = useSelector((state) => state.clubs.content);

  const { current_page, total_pages } = useSelector((state) => state.pagination.content);

  useEffect(() => {
    dispatch(resetClubsList());
    dispatch(fetchClubsPaginated({ ...query, page: 1 }));
  }, [dispatch, query])

  const fetchNextPage = (page) => {
    dispatch(fetchClubsPaginated({ ...query, page }));
  };

  return (
    <InfiniteScroll
      className={'overflow-hidden InfiniteScroll'}
      scrollableTarget="main-content"
      dataLength={clubs.length}
      next={() => fetchNextPage(current_page + 1)}
      hasMore={current_page < total_pages}
      endMessage={
        <p style={{ textAlign: 'center' }}>
          <b>Fim da listagem</b>
        </p>
      }
    >
      <div className="col-xl-12">
        <div className="row ps-2 pe-2">
          {clubs && clubs.map((club, index) => (
            <div key={index} className="col-md-6 col-sm-6 pe-2 ps-2 pb-4">
                <div className="card d-block rounded-3 overflow-hidden mb-0 mt-2">
                  <Link to={`/clube/${club.slug}`}><div className="card-body position-relative h100 bg-image-cover bg-image-center" style={{ backgroundImage: `url("${club.capa_url}")` }} /></Link>
                  <div className="card-body d-block w-100 pe-4 pb-4 pt-0 text-left position-relative">
                    <div className="clearfix" />
                    <Link to={`/clube/${club.slug}`}><h4 className="fw-700 font-xsss mt-3 mb-1">{club.nome}</h4></Link>
                    <Link to={`/clube/${club.slug}`}><p className="fw-500 font-xsssss text-grey-500 mt-0 mb-3 lh-3">{club.categoria.nome}</p></Link>
                    <span className="position-absolute right-15 top-0 d-flex align-items-center">
                      {user.id === club.usuario &&
                      <Link to={`/admin/clubes/editar/${club.id_clube}`} className="d-lg-block">
                        <i className="feather-edit btn-round-md font-md bg-primary-watermelon text-white"/>
                      </Link>
                      }
                      <div className="d-lg-block ps-2">
                        <Link to={`/clube/${club.slug}`}>
                          <i className="feather-eye btn-round-md font-md bg-gradiant-dark-green text-white"/>
                        </Link>
                      </div>
                    </span>
                  </div>
                </div>
            </div>
          ))}
        </div>
      </div>
    </InfiniteScroll>
  );
}

