import React, {useEffect} from 'react';
import { useDispatch } from 'react-redux';
import {logout} from "../../../redux/actions/session";
import history from "../../../utils/history";

const LogoutHandle = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
    history.push('/auth');
  }, [dispatch]);

  return (
    <>
    </>
  );
};

export default LogoutHandle;
