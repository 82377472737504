/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resendEmailValidadion } from '../../../../redux/actions/session';

const ResendValidadionEmail = () => {
  
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (formData) => {
    dispatch(resendEmailValidadion(formData));
  };

  return (

    <div className="row p-0 m-0">
      <div
        className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
        style={{ backgroundImage: `url(/app/images/livapp-compartilhe-suas-historias.png)` }}
      />

      <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
        <div className="card shadow-none border-0 ms-auto me-auto login-card">
          <div className="card-body rounded-0 text-left">
            <h2 className="fw-700 display1-size display2-md-size mb-4">
              Validar Sua Conta
              <br />
            </h2>
            <form onSubmit={handleSubmit(onSubmit)}>

              <div className="form-group icon-input mb-3">
                <i className="font-sm ti-email text-grey-500 pe-0" />
                <input
                  {...register('email', { required: true })}
                  type="text"
                  style={errors.email && { border: '2px solid #FC6E83' }}
                  className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                  placeholder="e-mail cadastrado"
                />
              </div>
              <div className="form-group mb-1">
                {/* Botão de enviar informações */}
                <input
                  className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0"
                  type="submit"
                  value="Reenviar Validação do Cadastro"
                />
              </div>
            </form>

            <div className="col-sm-12 p-0 text-left">
              <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                Já tem uma conta?
                <Link to="/auth" className="fw-700 ms-1">Faça login</Link>
              </h6>
            </div>
            <div className="col-sm-12 p-0 text-left">
              <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                Não tem uma conta?
                <Link to="/auth/registrar" className="fw-700 ms-1">Registre-se</Link>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResendValidadionEmail;
