import React from 'react';
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { logout } from '../../../redux/actions/session';
import {ReactComponent as Email} from '../../../../public/assets/images/icones/email.svg'
import {ReactComponent as Senha} from '../../../../public/assets/images/icones/cadeado.svg'
import {ReactComponent as Usuario} from '../../../../public/assets/images/icones/username.svg'
import {ReactComponent as Avatar} from '../../../../public/assets/images/icones/avatar.svg'
import Header from "../../layout/header/Header";


const Configuration = () => {
  const dispatch = useDispatch();

  return (
    <>
      <Header backPage={`/home`} titlePage={`Configurações`} />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-xl-12">
                <div className="card-body p-lg-5 p-4 w-100 border-0">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="nav-caption fw-600 font-xssss text-grey-500 mb-2">Gerenciamento</div>
                      <ul className="list-inline mb-4">
                        <li className="list-inline-item d-block border-bottom me-0">
                          <Link to="/config/profile" className="pt-2 pb-2 d-flex align-items-center">
                            <div className="btn-round-md bg-primary-dark-green me-3">
                              <Usuario />
                            </div>
                            <h4 className="fw-600 font-xsss mb-0 mt-0">Dados Pessoais</h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                          </Link>
                        </li>
                        <li className="list-inline-item d-block border-bottom me-0">
                          <Link to="/config/avatar" className="pt-2 pb-2 d-flex align-items-center">
                            <div className="btn-round-md bg-primary-dark-green me-3">
                              <Avatar />
                            </div>
                            <h4 className="fw-600 font-xsss mb-0 mt-0">Avatar</h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                          </Link>
                        </li>
                        <li className="list-inline-item d-block border-bottom me-0">
                          <Link to="/config/password" className="pt-2 pb-2 d-flex align-items-center">
                            <div className="btn-round-md bg-primary-dark-green me-3">
                              <Senha />
                            </div>
                            <h4 className="fw-600 font-xsss mb-0 mt-0">Senha</h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                          </Link>
                        </li>
                        <li className="list-inline-item d-block me-0">
                          <Link to="/config/email" className="pt-2 pb-2 d-flex align-items-center">
                            <div className="btn-round-md bg-primary-dark-green me-3">
                              <Email />
                            </div>
                            {/* <i className="btn-round-md bg-primary-watermelon text-white feather-mail font-md me-3"></i> */}
                            <h4 className="fw-600 font-xsss mb-0 mt-0">E-mail</h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                          </Link>
                        </li>
                      </ul>
                      <div className="nav-caption fw-600 font-xsss text-grey-500 mb-2">Sistema</div>
                      <ul className="list-inline">
                        <li className="list-inline-item d-block me-0">
                          <Link to="auth" onClick={() => dispatch(logout())} className="pt-2 pb-2 d-flex align-items-center">
                            <i className="btn-round-md bg-primary-dark-green text-white feather-log-out font-md me-3"></i>
                            <h4 className="fw-600 font-xsss mb-0 mt-0">Sair</h4>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Configuration;
