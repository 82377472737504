import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {Link, useLocation, useParams} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import history from '../../../../utils/history';

import { getStepsTypes } from '../../../../redux/actions/steps';

import Header from "../../../layout/header/Header";
import Pagetitle from "../../../layout/header/Pagetitle";

const StepChooseType = () => {
  
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  /* Recupera a sala que o usuário quer adicionar uma Fase */
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  let idRoom = query.get("room");

  /* Link do botão voltar */
  const linkReturnPage = `/admin/salas/editar/${idRoom}?tab=step`;

  /* Recuperando os tipos de fases */
  const types = useSelector((state) => state.steps.options);

  /* Variavel para guardar o tipo de fase escolhida */
  const [typeStepChosen, setTypeStepChosen] = useState(0);
 
  /* Redireciona a navegação para e etapa seguinte */
  const nextPage = () => {
    history.push("/admin/fases/criar/detalhes-fase/?room="+idRoom+'&typeStep='+typeStepChosen)
  }
  
  /* Recuperando os Tipos de Fases */
  useEffect(() => {
    async function getData() {
      await dispatch(getStepsTypes());
    }

    getData();
  }, []);

  /* Função para guardar o id da fase escolhida */
  const onActionSelectingTypeStep = (e, idTypeStep) => {
    setTypeStepChosen(idTypeStep);
  }

   return (
    <>
      <Header returnPage={linkReturnPage} title={'Adicionar Fase'} />
      <div className="main-content right-chat-active no-bottom-bar">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-xl-12">
                <Pagetitle title="Passo 1" subTitle="Escolha um tipo de fase" />
                <div className="row">
                  <form>
                    <div className="form-group">
                      {types.map((type, index) => (
                        <div key={index}>
                          <label className={`container_radio version_2  ${typeStepChosen === type.value ? 'active' : ''}`} onClick={ ev => onActionSelectingTypeStep(ev, type.value)}>
                            <strong>{type.label}</strong>
                            <br />
                            <small>{type.description}</small>
                            <input

                              type="radio"
                              name="typeStep"
                              value={type.value}
                              className=""
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      ))}
                    </div>
                    <div className="col-lg-12">
                      <div className="buttonFooter">
                        <button onClick={() => nextPage()} className="rounded-3 bg-primary-dark-green mb-2 mt-4 p-3 w-100 fw-600 fw-700 text-center font-xssss mont-font text-uppercase ls-3 text-white d-block border-0">Proximo</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StepChooseType;
