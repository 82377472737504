import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { fetchLoggedUser, updateProfileUser } from "../../../redux/actions/users";
import Header from "../../layout/header/Header";

const ProfileEdit = () => {

  const dispatch = useDispatch();
  const profile = useSelector((state) => state.session.user);

  const { register, handleSubmit, reset, formState: { errors } } = useForm({});

  useEffect(() => {
    dispatch(fetchLoggedUser())
  }, [dispatch])

  useEffect(() => {
    reset({
      nome: profile.name,
      sobrenome: profile.sobrenome,
      apelido: profile.apelido,
      username: profile.username,
    })
  }, [profile])

  const onSubmit = (formData) => {
    dispatch(updateProfileUser(formData));
  };

  return (
    <>
      <Header backPage={`/config`} titlePage={`Dados Pessoais`} />
      <div className="main-content right-chat-active w-100">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12">
                <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="form-gorup">
                          <label className="mont-font fw-600 font-xssss">Nome</label>
                          <input
                            {...register('nome', { required: true })}
                            className={'form-control' + `${errors.nome ? " inputError" : ''}`}
                            autoComplete="off"
                            type="text"
                            placeholder="Nome"
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 mb-3">
                        <div className="form-gorup">
                          <label className="mont-font fw-600 font-xssss">Sobrenome</label>
                          <input
                            {...register('sobrenome')}
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            placeholder="Sobrenome"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="form-gorup">
                          <label className="mont-font fw-600 font-xssss">Como gostaria de ser chamado?</label>
                          <input
                            {...register('apelido')}
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            placeholder="Como gostaria de ser chamado?"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 mb-3">
                        <div className="form-gorup">
                          <label className="mont-font fw-600 font-xssss">Username</label>
                          <input
                            {...register('username', { required: true })}
                            className={'form-control' + `${errors.username ? " inputError" : ''}`}
                            autoComplete="off"
                            type="text"
                            placeholder="Username"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 mb-0">
                        <button type="submit" className="bg-gradiant-dark-green text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">SALVAR</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfileEdit;
