import React from 'react';
import ButtonActionCheckinStep from '../../../components/steps/ButtonActionCheckinStep';
import ButtonActionCheckoutStep from '../../../components/steps/ButtonActionCheckoutStep';
import StatusStep from "../../../components/steps/StatusStep";
import {Link} from "react-router-dom";

export default function ListSteps ({steps = [], participate = {}, slugSala = {}, isAdmin = false, ...props}) {
  return (
    <div>
      {steps.map((step, index) => (
        <>
          <div key={`step_${index + 1}`}  className="card w-100 rounded-xxl shadow-xss border-0 mb-3">

            <div className="card-body">
              <div className="mb-2">
                <span className="badge badge-light text-primary-watermelon">Não iniciada</span>
              </div>
              <h4 className="fw-700 text-grey-900 font-xsss mt-1 mb-3 text-left w-100">
                {step.nome_fase}
              </h4>

              <div className="d-flex spaceStep">
                <h5 className="font-xssss mb-2 text-grey-600 fw-600 lh-20">{step.descricao_fase}</h5>
              </div>

              <div className="card-body p-0">
                <Link to={`/fase/${step.id_fase}`} className="font-xsssss fw-700 ps-3 pe-3 lh-32 float-right mt-4 text-uppercase rounded-3 ls-2 bg-primary-dark-green d-inline-block text-white me-1">ENTRAR</Link>
              </div>
            </div>

          </div>
        </>
      ))}
    </div>
  )
}
