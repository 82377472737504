import React, {useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {userConfirmeRegistration} from '../../../../redux/actions/session';

const ConfirmRegistrationHandle = () => {
  const dispatch = useDispatch();
  
  let queryString = useParams();
  
  useEffect(() => {
    dispatch(userConfirmeRegistration(queryString.emailUser, queryString.token));
  }, [dispatch]);

  return (
    <>
    </>
  );
};

export default ConfirmRegistrationHandle;
