/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { registerNewUser } from '../../../../redux/actions/session';

const Register = () => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (formData) => {
    dispatch(registerNewUser(formData));
  };

  function openTermos() {
    window.open("https://drive.google.com/file/d/1n34yJnyO7Q9JRMgzVvdav7m1f7lFNxtC/view?usp=sharing");
  }

  return (
    <div className="row p-0 m-0">
      <div
        className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
        style={{ backgroundImage: `url(/assets/images/livapp-compartilhe-suas-historias.png)` }}
      />
      <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
        <div className="card shadow-none border-0 ms-auto me-auto login-card">
          <div className="card-body rounded-0 text-left">
            <h2 className="fw-700 display1-size display2-md-size mb-4">
              Cadastre-se
            </h2>

            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group icon-input mb-3">
                <i className="font-sm ti-user text-grey-500 pe-0" />
                <input
                  {...register('nome', { required: true })}
                  className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                  style={errors.nome && { border: '2px solid #FC6E83' }}
                  placeholder="Nome"
                  type="text"
                  autoComplete="none"
                />
              </div>

              <div className="form-group icon-input mb-3">
                <i className="font-sm ti-email text-grey-500 pe-0" />
                <input
                  {...register('email', { required: true })}
                  type="text"
                  style={errors.email && { border: '2px solid #FC6E83' }}
                  className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                  placeholder="E-mail"
                  autoComplete="none"
                />
              </div>

              <div className="form-group icon-input mb-3">
                <i className="font-sm ti-email text-grey-500 pe-0" />
                <input
                  {...register('email_confirmation', { required: true })}
                  type="text"
                  style={errors.email_confirmation && { border: '2px solid #FC6E83' }}
                  className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                  placeholder="Confirme seu e-mail"
                  autoComplete="none"
                />
              </div>

              <div className="form-group icon-input mb-3">
                <i className="font-sm ti-lock text-grey-500 pe-0" />
                <input
                  {...register('password', { required: true })}
                  type="password"
                  style={errors.password && { border: '2px solid #FC6E83' }}
                  className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                  placeholder="Senha"
                  autoComplete="none"
                />
              </div>

              <div className="form-group icon-input mb-3">
                <i className="font-sm ti-lock text-grey-500 pe-0" />
                <input
                  {...register('password_confirmation', { required: true })}
                  type="password"
                  style={errors.password_confirmation && { border: '2px solid #FC6E83' }}
                  className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                  placeholder="Confirme sua Senha"
                  autoComplete="none"
                />
              </div>

              <div className="form-check text-left mb-3">
                <input
                  {...register('termo', { required: true })}
                  type="checkbox"
                  style={errors.termo && { border: '2px solid #FC6E83' }}
                  className="form-check-input mt-2"
                  id="term"
                />
                <label htmlFor="term" className="form-check-label font-xsss mb-3 text-grey-500">Li e aceito os</label> <a className="" onClick={ () => openTermos()} rel="noopener noreferrer">termos de uso</a>
              </div>
              <input type="submit" className="form-control style2-input text-white fw-600 bg-dark border-0 p-0 mb-3" value="Cadastrar" />
            </form>

            <div className="col-sm-12 p-0 text-left">
              <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                Já tem uma conta?
                <Link to="/auth" className="fw-700 ms-1">Faça Login</Link>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
