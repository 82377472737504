import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import {useForm} from "react-hook-form";
import {updatePasswordUser, updateProfileUser} from "../../../redux/actions/users";
import {useDispatch} from "react-redux";
import Header2 from "../../layout/header/Header";
import Header from "../../layout/header/Header";

const PasswordEdit = () => {
  
  const { register, handleSubmit, reset, formState: { errors } } = useForm({});
  const dispatch = useDispatch();

  const onSubmit = (formData) => {
    dispatch(updatePasswordUser(formData));
    reset({
      password_atual: '',
      password: '',
      password_confirmation: ''
    })
  };

  return (
  <>
    <Header2 title="Mudar E-mail" />
    <Header backPage={`/config`} titlePage={`Editar Senha`} />
    <div className="main-content right-chat-active w-100">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left pe-0">
          <div className="row">
            <div className="col-xl-12">
              <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="form-gorup">
                        <label className="mont-font fw-600 font-xssss">Senha Atual</label>
                        <input
                          {...register('password_atual', { required: true })}
                          className={'form-control' + `${errors.password_atual ? " inputError" : ''}`}
                          autoComplete="off"
                          type="password"
                          placeholder="Senha Atual"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <div className="form-gorup">
                        <label className="mont-font fw-600 font-xssss">Nova Senha</label>
                        <input
                          {...register('password', { required: true })}
                          className={'form-control' + `${errors.password ? " inputError" : ''}`}
                          autoComplete="off"
                          type="password"
                          placeholder="Nova Senha"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <div className="form-gorup">
                        <label className="mont-font fw-600 font-xssss">Confirmar Nova Senha</label>
                        <input
                          {...register('password_confirmation', { required: true })}
                          className={'form-control' + `${errors.password_confirmation ? " inputError" : ''}`}
                          autoComplete="off"
                          type="password"
                          placeholder="Confirmar Nova Senha"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 mb-0">
                      <button className="bg-gradiant-dark-green text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">SALVAR</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default PasswordEdit;
