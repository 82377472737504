import React from 'react';
import { deleteStep } from '../../../../../redux/actions/steps';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

const StepComponent = ({ step, roomId }) => {
  const dispatch = useDispatch();

  const {
    id,
    nome,
    ordem,
  } = step;

  const onDelete = () => {
    dispatch(deleteStep(id, roomId))
  }

  return (
    <>
      <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
        <div className="card-body p-0 ms-lg-5">
          <h4 className="fw-700 text-grey-900 mt-2">{'#' + ordem + ' - ' + nome}</h4>
        </div>
        <div className="card-body p-0 d-flex">
          <Link to={`/admin/salas/editar/${roomId}/editar-fase/${id}`} className="btn-round-sm bg-greylight me-2 mt-2">
            <span className="ti-pencil font-xss text-grey-900" />
          </Link>
          <Link onClick={() => onDelete()} className="btn-round-sm bg-greylight mr-2 mt-2">
            <span className="ti-trash font-xss text-grey-900" />
          </Link>
        </div>
      </div>
    </>
  );
}

export default StepComponent;
