import React, { useEffect } from 'react';
import { Tabs } from 'react-tabs';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { fetchPublisher } from "../../../../redux/actions/publishers";
import Header2 from "../../../layout/header/Header";

const PublishersDetail = () => {
  const dispatch = useDispatch();
  const publisher = useSelector((state) => state.publishers.current);

  let { id } = useParams();

  useEffect(() => {
    dispatch(fetchPublisher(id));
  }, [dispatch, id]);

  return (
    <>
      <Header2 title={"Editoras"} />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-xl-12">
                <Tabs defaultIndex={0} id="TabRoom" className="mb-3">
                  <div className="row">
                    <div className="col-xl-12 col-xxl-12 col-lg-12">
                      <div className="card d-block mt-3 border-0 shadow-xss bg-white p-lg-5 p-4">
                        <h2 className="fw-700 font-lg mt-3 mb-2">{publisher.nome}</h2>
                        <p className="font-xsss fw-500 text-grey-500 lh-30 pe-5 mt-3 me-5"> tellus at, hendrerit arcu.</p>
                        <div className="clearfix"></div>
                        <p className="review-link font-xssss fw-600 text-grey-500 lh-3 mb-0">(456 ratings ) 2 customer review</p>
                      </div>
                    </div>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PublishersDetail;
