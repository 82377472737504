/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  categories: [],
  listClubsAdmin: [],
  listClubsSelectAdmin: [],
  current: {}
};

const reducers = {
  updateCategoriesClubs: (state, action) => {
    state.categories = action.payload;
  },
  updateListClubsAdmin: (state, action) => {
    state.listClubsAdmin = action.payload;
  },
  updateListClubsSelectAdmin: (state, action) => {
    state.listClubsSelectAdmin = action.payload;
  },
  updateCurrentClubAdmin: (state, action) => {
    state.current = action.payload;
  },
};

export const clubsAdm = createSlice({
  name: 'clubsAdm',
  initialState,
  reducers,
});
