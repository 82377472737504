import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectAsync from 'react-select/async';
import DataListBooksInRooms from "../../../../components/Admin/DataList/DataListBooksInRooms";
import { fetchBooks } from '../../../../../redux/actions/books';
import { addBookInRoom } from '../../../../../redux/actions/roomsAdm';
import { notification } from '../../../../../redux/actions/communication';

const StepThree = () => {
  const dispatch = useDispatch();
  const room = useSelector((state) => state.rooms.current);

  const [bookOption, setBookOption] = useState(null);

  const onChangeBooks = async (value) => {
    return await dispatch(fetchBooks({ q: value }));
  };

  const onChange = (book) => {
    setBookOption(book);
  };

  const onAddBook = async () => {
    if (!bookOption) {
      dispatch(notification('Por favor selecione um livro!'));
    } else {
      await dispatch(addBookInRoom(room.id, bookOption.value));
      setBookOption(null);
    }
  };

  return (
    <>
      <div className="col-xl-12">
        <div className="d-flex border-0 pb-4 pt-4">
          <div className="w-100 mb-0 mt-0 d-flex align-items-center">
            <form action="#" className="w-100">
              <div className="search-form-2">
                <i className="ti-search font-xss"></i>
                <SelectAsync
                  placeholder="Digite para procurar o livro..."
                  noOptionsMessage={() => "Pesquise pra ver mais opções"}
                  onChange={onChange}
                  value={bookOption}
                  defaultOptions
                  loadOptions={onChangeBooks}
                />
              </div>
            </form>
            <button onClick={() => onAddBook()} className="border-0 btn-round-md ms-2 bg-greylight theme-dark-bg rounded-3">
              <i className="feather-plus font-xss text-grey-500" />
            </button>
          </div>
        </div>
        <div className="row ps-2 pe-2">
            <DataListBooksInRooms idRoom={room.id} books={room.livros} />
        </div>
      </div>
    </>
  )
}

export default StepThree;
