import React, {useState} from 'react';
import DataListRooms from "../../../components/App/DataList/DataListRooms";
import {debounce} from "throttle-debounce";
import ButtonInsert from "../../../components/App/Actions/ButtonInsert";

const initialParams = {
  usuarioLogado: true
};

const RoomsMySelf = () => {

  const [params, setParams] = useState(initialParams);

  const searchFunction = debounce(800, (searchParam) => {
    if (searchParam) {
      setParams({
        ...params,
        q: searchParam,
      });
    } else {
      setParams(initialParams);
    }
  });

  return (
    <>
      <div className="search-form-2">
        <i className="ti-search font-xss" />
        <input
          type="text"
          className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0 mb-3"
          placeholder="Procure aqui..."
          onChange={(event) => searchFunction(event.target.value)}
        />
      </div>
      <DataListRooms  query={params} />
      <ButtonInsert linkInsert={"/admin/clubes/adicionar"} />
    </>
  );
}

export default RoomsMySelf;
