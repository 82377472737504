import React, { useEffect, useState } from 'react';
import history from '../../../utils/history';
import Header from "../../layout/header/Header";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import RoomsSpotlight from "./list/RoomsSpotlight";
import RoomsSearch from "./list/RoomsSearch";
import RoomsMySelf from "./list/RoomsMySelf";
import { useParams } from "react-router-dom";
import ButtonInsert from "../../components/App/Actions/ButtonInsert";


const RoomsMain = () => {
  const [defaultIndex, setDefaultIndex] = useState(0);
  const { tab } = useParams();

  useEffect(() => {
    if (tab === 'gestao') {
      setDefaultIndex(2);
      history.replace();
    } else if (tab === 'pesquisa') {
      setDefaultIndex(1);
      history.replace();
    }
  }, [tab]);

  return (
    <>
      <Header backPage={'/'} titlePage={'Salas'} />

      <Tabs defaultIndex={defaultIndex} id="TabRoom" className="pt-5">
        <TabList className="nav d-flex w-100 bg-primary-dark-green"  id="tab" role="tablist">
          <div className="container">
            <div className="row">
              <Tab className="col-4 text-center fw-700 font-xssss pt-3 pr-20 pl-20 ls-1 d-inline-block text-white pb-1">DESTAQUES</Tab>
              <Tab className="col-4 text-center fw-700 font-xssss pt-3 pr-20 pl-20 ls-1 d-inline-block text-white pb-1">FAVORITOS</Tab>
              <Tab className="col-4 text-center fw-700 font-xssss pt-3 pr-20 pl-20 ls-1 d-inline-block text-white pb-1">GESTÃO</Tab>
            </div>
          </div>
        </TabList>
        <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0 tab-active">
              <div className="row">
                <div className="col-xl-12">
                  <TabPanel className="w-100"><RoomsSpotlight /></TabPanel>
                  <TabPanel className="w-100"><RoomsSearch /></TabPanel>
                  <TabPanel className="w-100"><RoomsMySelf /></TabPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Tabs>
    </>
  );
}

export default RoomsMain;
