import { publishers } from '../reducers/publishers';
import { errorNotification, successNotification } from './communication';
import { updatePagination } from './pagination';
import * as data from '../../data/requests';
import history from '../../utils/history';

export const {
  updatePublishers,
  updateCurrentPublisher,
  updatePublishersPaginated,
} = publishers.actions;

export const fetchPublishers = () => async dispatch => {
  try {
    const response = await data.get('editora');
    if (response.status === 200) {
      dispatch(updatePublishers(response.data.data));
    }
  } catch (error) {
    console.error('Erro ao retornar editoras cadastradas!');
  }
};

export const fetchPublisher = id => async dispatch => {
  try {
    const response = await data.get(`editora/${id}`);
    if (response.status === 200) {
      dispatch(updateCurrentPublisher(response.data.data));
    }
  } catch (error) {
    dispatch(
      errorNotification('Erro ao retornar informação da editora selecionada!'),
    );
  }
};

export const fetchPublishersPaginated = page => async dispatch => {
  try {
    let response;

    if (page) {
      response = await data.get(`editora?page=${page}`);
    } else {
      response = await data.get(`editora`);
    }

    if (response.status === 200) {
      dispatch(updatePublishersPaginated(response.data.data));
      dispatch(updatePagination(response.data.meta.pagination));
      return response.data.data;
    }
  } catch (error) {
    console.error('Erro ao retornar editoras paginadas!');
  }
};

export const searchPublisher = query => async dispatch => {
  try {
    const response = await data.get(`/editora?q=${query}`);
    if (response.status === 200) {
      dispatch(updatePublishers(response.data.data));
      return response.data.data.map(publisher => {
        return {
          value: publisher.id_editora,
          label: publisher.nome,
        };
      });
    }
  } catch (error) {
    console.error('Erro ao procurar Editoras!');
  }
};

export const savePublisher = params => async dispatch => {
  try {
    const response = await data.post('editora', params);
    if (response.status === 201) {
      dispatch(successNotification('Editora inserida com sucesso'));
      history.push('/editoras');
    }
  } catch (error) {
    console.error('Erro ao salvar editora!');
  }
};

export const editPublisher = (formData, id) => async dispatch => {
  try {
    const response = await data.postForm(`editora/${id}`, formData);
    if (response.status === 200) {
      dispatch(successNotification('Editora atualizada com sucesso'));
    }
  } catch (error) {
    console.error('Erro ao editar editora!');
  }
};
