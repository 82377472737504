import React from 'react';
import { useSelector } from 'react-redux';
import './loading.css';

const Loading = () => {
  const loadingStatus = useSelector((state) => state.communication.loadingStatus);
  return (
    <div>
      { loadingStatus > 0
      && (
      <div className="overlay">
        <div className="loader book">
          <figure className="page" />
          <figure className="page" />
          <figure className="page" />
        </div>
      </div>
      )}
    </div>
  );
};

export default Loading;
