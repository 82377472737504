import React from 'react';
import Postview from "./Postview";
import { Link } from 'react-router-dom';

export default function ListFeeds({ feeds = [], rooms = {}, idStep = {}, ...props }) {
  return (
    <div style={{ textAlign: 'center' }} className="pt-4 pb-3 mb-3">
        {
          feeds.length < 1 && (
            <div className="card-body pt-0 ">
              <h4 className="fw-700 font-xss mt-0 lh-28 mb-0">
                Ainda não existe nenhum Feed
              </h4>
              <h6 className="font-xsssss text-grey-500 fw-600 mt-0 mb-2">
                Seja o primeiro a Postar
              </h6>
              <Link to={`/admin/salas/fases/${idStep}`}>
                <i style={{ color: "#FF8C85" }} className="feather-plus-circle font-xl" />
              </Link>
            </div>
          )
        }

        {feeds.map((feed, index) => (
          <Postview
            key={index}
            idFeed={feed.id}
            superLike={feed.superCurtida}
            roomUserAdmin={rooms.usuario}
            idStep={idStep}
            postvideo=""
            postimage={feed.midia_url}
            avatar={feed.usuario.avatar_url}
            userFeed={feed.usuario}
            time={feed.criado_em}
            des={feed.conteudo}
          />
        ))}
      </div>
  )
}
