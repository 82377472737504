import React, { useEffect, useState } from 'react';
import { Card, Image, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {Link, useParams} from "react-router-dom";
import { fetchBookById } from "../../../../redux/actions/books";
import Header2 from "../../../layout/header/Header";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import BookInfo from "./Tabs/BookInfo";
import DataListRooms from "../../../components/App/DataList/DataListRooms";

const BooksDetail = () => {
  
  const dispatch = useDispatch();
  const book = useSelector(state => state.books.current);

  const initialParams = {};
  const [params, setParams] = useState(initialParams);

  let { id } = useParams();

  useEffect(() => {
    dispatch(fetchBookById(id)).then(function(data) {
      if (data.id_livro) {
        setParams({
          livro: data.id_livro,
        });
      }
    });

  }, []);



  return (
    <>
      <Header2 title={"Livros"} />
      <Tabs defaultIndex={0} id="TabRoom" className="">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <Container className="book-details border-0 bg-gradiant-dark-green">
              <Card.Body className="book_main w-100 left">
                <div className="d-flex justify-content-end my-2" />
                <Card
                  className=""
                  style={{
                    width: '13rem',
                    marginInline: 'auto',
                    borderRadius: '15px',
                  }}
                >
                  <Link to={`/admin/livros/editar/${book.id_livro}`} className="position-absolute bottom-15 mb-2 left-15 top-10 z-index-1">
                    <i className="btn-round-sm bg-melancia text-white font-sm feather-edit" />
                  </Link>
                  <Card.Img
                    variant="top"
                    className="image-shadow"
                    src={book.capa_url}
                    style={{ borderRadius: '15px' }}
                  />
                </Card>
              </Card.Body>
            </Container>
            <section>
              <div className="gap no-gap">
                <div className="profile-info">
                    <TabList className="nav nav-fillnav-outline-icon tab-label-active">
                      <Tab className="nav-item"><span className="nav-link">DETALHES</span></Tab>
                      <Tab className="nav-item"><span className="nav-link">SALAS</span></Tab>
                    </TabList>
                </div>
              </div>
            </section>
            <TabPanel className="w-100">
              <BookInfo />
            </TabPanel>
            <TabPanel className="w-100">
              <>
                <div className="card w-100 rounded-xxl border-0 mb-3">
                  <div className="card-body d-block p-4">
                    <DataListRooms query={params} />
                  </div>
                </div>
              </>
            </TabPanel>
          </div>
        </div>
      </Tabs>
    </>
  
)};

export default BooksDetail;
