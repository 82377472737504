import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { fetchBooks, resetBookList } from '../../../../redux/actions/books';

const DataListBooks = ({ query = '' }) => {

  const dispatch = useDispatch();
  const books = useSelector((state) => state.books.content);

  const { current_page, total_pages } = useSelector((state) => state.pagination.content);

  useEffect(() => {
    dispatch(resetBookList());
    dispatch(fetchBooks({ ...query, page: 1 }));
  }, [dispatch, query])

  const fetchNextPage = (page) => {
    dispatch(fetchBooks({ ...query, page }));
  };

  return (
    <InfiniteScroll
      className={'overflow-hidden InfiniteScroll'}
      scrollableTarget="main-content"
      dataLength={books.length}
      next={() => fetchNextPage(current_page + 1)}
      hasMore={current_page < total_pages}
      endMessage={
        <p style={{ textAlign: 'center' }}>
          <b>Fim da listagem</b>
        </p>
      }
    >
      <div className="row ps-2 pe-1 pb-5" style={{ textAlign: 'center' }}>
        {books.map((book, index) => (
          <div key={index} className="col-md-3 col-xss-6 pe-2 ps-2">
            <Link to={`/livro/${book.slug}`}>
              <div className="card h300 d-block border-0 shadow-xss rounded-3 bg-gradiant-bottom overflow-hidden mb-3 bg-image-cover" style={{ backgroundImage: `url("${book.capa_url}")` }}>
                <div className="card-body d-block w-100 position-absolute bottom-0 text-center">
                  <div className="clearfix" />
                  <h4 className="fw-600 position-relative z-index-1 ls-3 font-xs text-white mt-2 mb-1">{book.titulo}</h4>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </InfiniteScroll>
  );
}

export default DataListBooks;
