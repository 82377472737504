import React from 'react';
import { Link } from "react-router-dom";
import Header2 from "../../../layout/header/Header";

const AuthorsAdminIndex = () => {

  return (
    <>
      <Header2 title={"Autores"} />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-xl-12">
                <div className="card-body p-lg-5 p-4 w-100 border-0">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="nav-caption fw-600 font-xssss text-grey-500 mb-2">Gerenciamento</div>
                      <ul className="list-inline mb-4">
                        <li className="list-inline-item d-block me-0">
                          <Link to="/admin/autores/adicionar" className="pt-2 pb-2 d-flex align-items-center">
                            <i className="btn-round-md bg-primary-gradiant text-white feather-file-plus font-md me-3"></i>
                            <h4 className="fw-600 font-xsss mb-0 mt-0">Adicionar</h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default AuthorsAdminIndex;
