import { clubs } from '../reducers/clubs';
import { clubsAdm } from '../reducers/clubsAdm';
import { errorNotification, successNotification } from './communication';
import { updatePagination } from './pagination';
import * as data from '../../data/requests';
import history from '../../utils/history';

import { fetchClubRooms } from './rooms';

export const {
  updateCurrentClub,
  updateClubs,
  updateClubsPaginated,
  updateMembersCurrentClub,
  resetClubs
} = clubs.actions;

export const {
  updateListClubsSelectAdmin
} = clubsAdm.actions;

export const fetchClub = id => async dispatch => {
  try {
    const response = await data.get(`clube/${id}`);
    if (response.status === 200) {
      dispatch(updateCurrentClub(response.data.data));
      await dispatch(fetchClubRooms(response.data?.data.id_clube));
      await dispatch(fetchMembersClub(response.data?.data.id_clube));
      return response.data.data;
    }
  } catch (error) {
    console.error('Erro ao retornar informações do Clube!');
  }
};

export const fetchClubLoggedUserOptions = () => async dispatch => {
  try {
    const response = await data.get('/clube?usuarioLogado=true&spg=true');
    if (response.status === 200) {
      dispatch(
        updateListClubsSelectAdmin(
          response.data.data.map(clube => {
            return {
              value: clube.id_clube,
              label: clube.nome,
            };
          }),
        ),
      );
    }
  } catch (error) {
    console.error('Erro ao retornar informações do Clube!');
  }
};

export const fetchClubsPaginated = query => async dispatch => {
  const queryParams = '?' + new URLSearchParams(query);

  try {
    const response = await data.get(`clube${queryParams}`);
    if (response.status === 200) {
      dispatch(updateClubsPaginated(response.data.data));
      dispatch(updatePagination(response.data.meta.pagination));
      /*return response.data.data.map(clubs => {
        return {
          value: clubs.id,
          label: clubs.nome,
        };
      });*/
    }
  } catch (error) {
    console.error('Erro ao retornar clubes paginados!');
  }
};

export const searchClubs = query => async dispatch => {
  try {
    const response = await data.get(`/clube?q=${query}`);
    if (response.status === 200) {
      dispatch(updateClubs(response.data.data));
    }
  } catch (error) {
    console.error('Erro ao procurar clubes cadastrados!');
  }
};

export const searchUserClubs = query => async dispatch => {
  try {
    const response = await data.get(`/clube?usuarioLogado=true&q=${query}`);
    if (response.status === 200) {
      dispatch(updateClubs(response.data.data));
      return response.data.data.map(club => {
        return {
          value: club.id_clube,
          label: club.nome,
        };
      });
    }
  } catch (error) {
    console.error('Erro ao procurar clubes cadastrados!');
  }
};

export const fetchMembersClub = idClub => async dispatch => {
  try {
    const response = await data.get('clube/' + idClub + '/membros');
    if (response.status === 200) {
      dispatch(updateMembersCurrentClub(response.data.data));
    }
  } catch (error) {
    console.error('Erro ao retornar membros do clube.');
  }
};

export const fetchClubs = () => async dispatch => {
  try {
    const response = await data.get('clube');
    if (response.status === 200) {
      dispatch(updateClubs(response.data.data));
    }
  } catch (error) {
    console.error('Erro ao retornar clubes cadastrados!');
  }
};

export const saveClub = params => async dispatch => {
  try {
    const response = await data.post('clube', params);
    if (response.status === 201) {
      dispatch(successNotification('Clube Criado com Sucesso'));
      history.push('/clubes');
    }
  } catch (error) {
    console.error(error.data.message);
  }
};

export const followClub = (idClub, boolFollow) => async dispatch => {
  try {
    let response = undefined;

    if (boolFollow) {
      response = await data.post(`clube/${idClub}/seguir`);
    } else {
      response = await data.del(`clube/${idClub}/seguir`);
    }

    if (response.status === 201) {
      dispatch(fetchClub(idClub));
    }
  } catch (error) {
    console.error('Erro ao Seguir o Clube selecionado.');
  }
};

export const resetClubsList = () => dispatch => {
  dispatch(resetClubs());
};
