import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  admin : 0,
  current : {}
};

const reducers = {

};

export const roomsAdm = createSlice({
  name: 'roomsAdm',
  initialState,
  reducers,
});
