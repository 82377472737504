/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  content: {},
};

const reducers = {
  updatePagination: (state, action) => {
    state.content = action.payload;
  },
};

export const pagination = createSlice({
  name: 'pagination',
  initialState,
  reducers,
});
