import React, { useState } from 'react';
import { debounce } from 'throttle-debounce';
import DataListBooks from "../../../components/App/DataList/DataListBooks";

const initialParams = {};

const BooksAll = () => {
  const [params, setParams] = useState(initialParams);

  const searchFunction = debounce(800, (searchParam) => {
    if (searchParam) {
      setParams({
        ...params,
        q: searchParam,
      });
    } else {
      setParams(initialParams);
    }
  });

  return (
    <>
      <div className="main-content InfiniteScroll infiniright-chat-active w-100">
        <div className="middle-sidebar-bottom-submenu">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12">
                <div className="search-form-2">
                  <i className="ti-search font-xss" />
                  <input
                    type="text"
                    className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0 mb-3"
                    placeholder="Procure aqui..."
                    onChange={(event) => searchFunction(event.target.value)}
                  />
                </div>
                <DataListBooks query={params} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BooksAll;
