import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import StepComponent from './StepComponent';

import { getStepsByRoomId, changeOrder } from '../../../../../redux/actions/steps';

import { getItemStyle, getListStyle } from '../../../../../utils/drag-and-drop';

const StepTwo = () => {
  const dispatch = useDispatch();
  const steps = useSelector((state) => state.steps.content);

  let { id } = useParams();

  useEffect(() => {
    async function getData() {
      await dispatch(getStepsByRoomId(id));
    };

    getData();
  }, []);

  const onDragEnd = result => {
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }

    dispatch(changeOrder(source.index + 1, destination.index + 1, id, draggableId));
  };

  return (
    <>
      <div className="main-content right-chat-active no-bottom-bar">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-xl-12">
                <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                  <Link to={`/admin/fases/criar/escolher-tipo-fase?room=${id}`}>
                    <button className="rounded-xxl border-dashed mb-2 p-3 w-100 fw-600 fw-700 text-center font-xssss mont-font text-uppercase ls-3 text-grey-900 d-block text-dark">ADICIONAR FASE</button>
                  </Link>
                </div>


                
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        className="droppableList"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {steps.map((step, index) => (
                            <Draggable
                              key={index}
                              draggableId={`${step.id}`}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style,
                                  )}
                                >
                                  <StepComponent step={step} roomId={id} />
                                </div>
                              )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}
export default StepTwo;
