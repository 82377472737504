import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'throttle-debounce';
import { fetchPublishersPaginated, searchPublisher } from '../../../../redux/actions/publishers';
import DataListPublishers from "../../../components/App/DataList/DataListPublishers";
import Header2 from "../../../layout/header/Header";
import ButtonInsert from "../../../components/App/Actions/ButtonInsert";

const Publishers = () => {
  const dispatch = useDispatch();
  const publishers = useSelector((state) => state.publishers.content);

  useEffect(() => {
    dispatch(fetchPublishersPaginated())
  }, [dispatch]);

  const onSearch = debounce(800, (query) => {
    if (query.length > 2) {
      dispatch(searchPublisher(query));
    }

    if (query.length === 0) {
      dispatch(fetchPublishersPaginated());
    }
  });

  const onFetchNextPage = async (page) => {
    const result = await dispatch(fetchPublishersPaginated(page));
    return result;
  };

  return (
    <>
      <Header2 title={"Editoras"} />
      <ButtonInsert linkInsert={"/admin/editoras/adicionar"} />
      <div className="main-content InfiniteScroll right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-xl-12">
                <DataListPublishers dataList={publishers} fetchNextPage={onFetchNextPage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Publishers;
