import React, {useEffect, useState, createRef} from 'react';
import {Link, useParams} from 'react-router-dom';
import { useForm } from "react-hook-form";
import {useDispatch, useSelector} from 'react-redux';
import {fetchCategoriesClubs, fetchClubAdmin, editClub} from "../../../../redux/actions/clubsAdm";
import Header2 from "../../../layout/header/Header";
import Dropzone from "react-dropzone";

const ClubsEdit = () => {

  const dispatch = useDispatch();

  const [image, setImage] = React.useState(undefined);
  const [imageCover, setImageCover] = React.useState(undefined);
  const [imageDefaultCover, setimageDefaultCover] = React.useState([]);
  const [imageDefaultLogo, setimageDefaultLogo] = React.useState([]);

  const [displayImage, setDisplayImage] = useState(undefined);
  const [displayImageCover, setDisplayImageCover] = useState(undefined);

  const dropzoneRef = createRef();
  const dropzoneRefCover = createRef();

  const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point 
    if (dropzoneRef.current) {
      dropzoneRef.current.open()
    }
  };

  const openDialogCover = () => {
    // Note that the ref is set async,
    // so it might be null at some point 
    if (dropzoneRefCover.current) {
      dropzoneRefCover.current.open()
    }
  };

  const resetImageDefault = () => {
    setImage(imageDefaultLogo)
  };
  const resetImageCoverDefault = () => {
    setImageCover(imageDefaultCover)
  };
  
  const categoriesClub = useSelector((state) => state.clubsAdm.categories);
  
  const { register, handleSubmit, reset, formState: { errors } } = useForm({});

  const currentClub = useSelector((state) => state.clubsAdm.current);

  let { id } = useParams();

  const onChangeImage = (file) => {
    const reader = new FileReader();
    setImage(file);

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setDisplayImage(reader.result);
    }
  }

  const onChangeImageCover = (file) => {
    const reader = new FileReader();
    setImageCover(file);

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setDisplayImageCover(reader.result);
    }
  }

  const onSubmit = (form) => {
    
    const formData = new FormData();

    formData.append('nome', form.nome);
    formData.append('descricao', form.descricao);
    formData.append('categoria', form.categoria);
    
    if (image) {
      formData.append('imagemLogo', image, image.name);
    }

    if (imageCover) {
      formData.append('imagemPrincipal', imageCover, imageCover.name);
    }

    dispatch(editClub(formData, id));
  };

  useEffect(() => {
    dispatch(fetchCategoriesClubs())
    dispatch(fetchClubAdmin(id))
  }, [dispatch])

  useEffect(() => {
    reset({
      nome: currentClub.nome,
      categoria: currentClub.categoria?.id,
      descricao: currentClub.descricao,
    })
    
    setDisplayImage(currentClub.logo_url)
    setDisplayImageCover(currentClub.capa_url)
    
    setimageDefaultCover(currentClub.capa_url);
    setimageDefaultLogo(currentClub.logo_url);
  },[currentClub])

  return (
    <>
      <Header2 title={'Editar Clube'} />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-xl-12">
                <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">

                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">Nome</label>
                          <input
                            {...register('nome', { required: true })}
                            className={'form-control' + `${errors.nome ? " inputError" : ''}`}
                            autoComplete="off"
                            type="text"
                            placeholder="Nome"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">Categoria</label>
                          <select
                            {...register('categoria', { required: true })}
                            className={'form-control' + `${errors.categoria ? " inputError" : ''}`}
                            autoComplete="off"
                          >
                            <option value="">Selecione uma Categoria</option>
                            {categoriesClub.map((data, index) => (
                              <option key={index} value={data.id}>{data.nome}</option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-12 mb-3">
                        <label className="mont-font fw-600 font-xsss mb-2 text-dark">Descrição</label>
                        <textarea {...register("descricao")} className="form-control mb-0 p-3 h100 bg-greylight lh-16" rows="5" placeholder="Escreva sobre o clube..." ></textarea>
                      </div>

                      <div className="col-lg-12 mb-3">
                        <div className="card-body d-flex justify-content-between align-items-end p-0">
                          <div className="form-group mb-0 w-100">
                            <label className="mont-fnt fw-600 font-xsss mb-2">Logo *</label>
                            <Dropzone ref={dropzoneRef} onDrop={files => onChangeImage(files[0])}>
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div {...getRootProps()}>
                                    <div className="col-lg-12 mb-3">
                                      <div className="card mt-3 border-0">
                                        <input
                                          {...getInputProps()}
                                          name="logo"
                                          type="file"
                                          accept="image/png, image/gif, image/jpeg"
                                          onChange={(event) => onChangeImage(event.target.files[0])}
                                          className="form-control"
                                        />
                                        <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">

                                          {
                                            displayImage ?
                                              (<div className="row justify-content-center">
                                                <div className="col-lg-4 text-center">
                                                  <figure className="avatar ms-auto me-auto mb-0 mt-2 w100">
                                                    <img
                                                      alt="Logo do Clube"
                                                      className="shadow-sm rounded-3 w-100"
                                                      src={displayImage}
                                                    />
                                                  </figure>
                                                </div>
                                              </div>) :
                                              (
                                                <div>
                                                  <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                                  <span className="js-fileName">Arraste e solte ou clique para trocar</span>
                                                </div>
                                              )
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              )}
                            </ Dropzone>
                            <div className="col-lg-12 mt-3">
                              <div className="d-flex align-items-center pt-0 pe-4 pb-4">
                                {displayImage ? (
                                  <button type="button" onClick={() => { setDisplayImage(undefined); }} className="border-0 p-2 lh-20 w100 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Remover</button>
                                ) : (<button type="button" onClick={openDialog} className="border-0 p-2 lh-20 w100 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Adicionar</button>)
                                }
                                <button type="button" onClick={() => { resetImageDefault(); }} className="border-0 p-2 lh-20 w100 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl">Resetar</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 mb-3">
                        <div className="card-body d-flex justify-content-between align-items-end p-0">
                          <div className="form-group mb-0 w-100">
                            <label className="mont-fnt fw-600 font-xsss mb-2">Capa *</label>
                            <Dropzone ref={dropzoneRefCover} onDrop={files => onChangeImageCover(files[0])}>
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div {...getRootProps()}>
                                    <div className="col-lg-12 mb-3">
                                      <div className="card mt-3 border-0">
                                        <input
                                          {...getInputProps()}
                                          alt="Capa do Clube"
                                          name="logo"
                                          type="file"
                                          accept="image/png, image/gif, image/jpeg"
                                          onChange={(event) => onChangeImageCover(event.target.files[0])}
                                          className="form-control"
                                        />
                                        <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">

                                          {
                                            displayImageCover ?
                                              (<div className="row justify-content-center">
                                                <div className="col-lg-4 text-center">
                                                  <figure className="avatar ms-auto me-auto mb-0 mt-2 w100">
                                                    <img
                                                      alt="Capa do CLube"
                                                      className="shadow-sm rounded-3 w-100"
                                                      src={displayImageCover}
                                                    />
                                                  </figure>
                                                </div>
                                              </div>) :
                                              (
                                                <div>
                                                  <i className="ti-cloud-down large-icon me-3 d-block" />
                                                  <span className="js-fileName">Arraste e solte ou clique para trocar</span>
                                                </div>
                                              )
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              )}
                            </ Dropzone>
                            <div className="col-lg-12 mt-3">
                              <div className="d-flex align-items-center pt-0 pe-4 pb-4">
                                {displayImageCover ? (
                                  <button type="button" onClick={() => { setDisplayImageCover(undefined); }} className="border-0 p-2 lh-20 w100 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Remover</button>
                                ) : (<button type="button" onClick={openDialogCover} className="border-0 p-2 lh-20 w100 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Adicionar</button>)
                                }
                                <button type="button" onClick={() => { resetImageCoverDefault(); }} className="border-0 p-2 lh-20 w100 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl">Resetar</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <input
                        className="bg-gradiant-dark-green text-center text-white font-xsss fw-600 p-3 border-0 w175 rounded-3 d-inline-block"
                        type="submit"
                        value="Salvar"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ClubsEdit;
