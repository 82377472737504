import React from 'react';
import {useDispatch} from "react-redux";
import {publishRoom} from "../../../redux/actions/roomsAdm";
import {notification} from "../../../redux/actions/communication";

export default function ButtonPublishRoom ({idRoom = 0, currentStatus = 0}) {

  const dispatch = useDispatch();
  
  function publishFunction() {
    if (currentStatus === 0) {
      dispatch(publishRoom(idRoom));
    }else{
      dispatch(notification("Essa sala já foi publicada"));
    }
  }

  return (
    <>
      <button
        onClick={() => publishFunction()}
        title={ currentStatus === 0 ? 'Publicar Sala' : 'Sala Publicada'}
        type="button"
        className="float-right add-to-cart bg-dark text-white fw-700 ps-lg-5 pe-lg-5 text-uppercase font-xssss float-left border-dark border rounded-3 border-size-md d-inline-block mt-0 p-3 text-center ls-3">PUBLICAR</button>
    </>
  )
}
