import { successNotification } from './communication';
import * as data from '../../data/requests';
import { fetchRoom, updateCurrentRoom } from './rooms';
import history from '../../utils/history';

export const publishRoom = idRoom => async dispatch => {
  try {
    const response = await data.post('/sala/' + idRoom + '/publicar');
    if (response.status === 201) {
      dispatch(successNotification('Sala Publicada com Sucesso!'));
      dispatch(fetchRoom(idRoom));
    }
  } catch (error) {
    console.error('Erro ao Publicar Sala!');
  }
};

export const fetchRoomById = id => async dispatch => {
  try {
    const response = await data.get(`sala/${id}/edicao`);
    if (response.status === 200) {
      dispatch(updateCurrentRoom(response.data.data));
      return response.data.data;
    }
  } catch (error) {
    console.error('Erro ao retornar Sala por ID!');
    history.push('/salas');
  }
};

export const addBookInRoom = (idRoom, idBook) => async dispatch => {
  try {
    const response = await data.post(`/sala/${idRoom}/associar-livro`, {
      livro: idBook,
    });
    if (response.status === 201) {
      dispatch(fetchRoom(idRoom));
      return response.data.data;
    }
  } catch (error) {
    console.error('Erro ao associar um livro da sala.');
  }
};

export const deleteBookInRoom = (idRoom, idBook) => async dispatch => {
  try {
    const response = await data.del(`/sala/${idRoom}/remover-livro/${idBook}`);
    if (response.status === 204) {
      dispatch(fetchRoom(idRoom));
      return response.data.data;
    }
  } catch (error) {
    console.error('Erro ao retirar um livro da sala.');
  }
};
