import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Header from "../../../layout/header/Header";
import ListSteps from "../../../app/components/step/ListSteps";
import {fetchRoom} from "../../../../redux/actions/rooms";
import {useParams} from "react-router-dom";

export default function StepsByRoom() {

  const dispatch = useDispatch();

  const room = useSelector((state) => state.rooms.current);
  const user = useSelector((state) => state.session.user);

  const { idRoom } = useParams();
  const isAdmin = room.usuario === user.id;

  useEffect(() => {
    dispatch(fetchRoom(idRoom));
  }, []);

  return (
    <>
       <Header backPage={`/sala/${room.slug}`} titlePage={"Fases da Sala"} />

        <div className="" id="groupTabs">
          <div className="main-content-simple bg-step" id="step">
            <div className="middle-sidebar-bottom-internal">
              <div className="container">
                sdcsdcsdc
                <ListSteps
                  steps={room.fases}
                  participate={room.participante}
                  slugSala={room.slug}
                  isAdmin={isAdmin}
                />
              </div>
            </div>
          </div>
        </div>
    </>
  )
}
