import { clubs } from '../reducers/clubs';
import { errorNotification, successNotification } from './communication';
import * as data from '../../data/requests';
import { clubsAdm } from '../reducers/clubsAdm';
import history from '../../utils/history';

export const {
  updateCategoriesClubs,
  updateListClubsAdmin,
  updateCurrentClubAdmin,
} = clubsAdm.actions;

export const fetchCategoriesClubs = () => async dispatch => {
  try {
    const response = await data.get('categorias/clube');
    if (response.status === 200) {
      dispatch(updateCategoriesClubs(response.data.data));
    }
  } catch (error) {
    console.error('Erro ao obter categorias.');
  }
};

export const fetchClubsUserLogged = () => async dispatch => {
  try {
    const response = await data.get('/clube' + '?' + 'usuarioLogado=true');
    if (response.status === 200) {
      dispatch(updateListClubsAdmin(response.data.data));
    }
  } catch (error) {
    console.error(error.data.message);
  }
};

export const fetchClubAdmin = id => async dispatch => {
  try {
    const response = await data.get(`clube/${id}/edicao`);
    if (response.status === 200) {
      dispatch(updateCurrentClubAdmin(response.data.data));
    }
  } catch (error) {
    dispatch(errorNotification('Clube Não encontrado.'));
  }
};

export const editClub = (formData, id) => async dispatch => {
  try {
    const response = await data.postForm(`clube/${id}`, formData);
    if (response.status === 200 || response.status === 201) {
      dispatch(successNotification('Clube atualizado com sucesso.'));
      history.push(`/admin/clubes/editar/${id}`);
    }
  } catch (error) {
    console.error(error.data.message);
  }
};
