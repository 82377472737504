import React from 'react';

const PasswordConfirmation = () => (
  <div className="main-content pt-0 bg-white ps-0 pe-0">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-8 text-center default-page vh-100 align-items-center d-flex">
          <div className="card border-0 text-center d-block p-0">
            <img src="app/imagens/autenticacao/confirmacao.png" alt="icon" className="w200 mb-4 ms-auto me-auto pt-md-5" />
            <h1 className="fw-700 text-grey-900 display3-size display4-md-size">E-mail de recuperação enviado com sucesso!</h1>
            <h2>Cheque sua caixa de e-mails, caso necessário verifique a caixa de spam.</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PasswordConfirmation;
