import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {fetchRoom} from "../../../../redux/actions/rooms";
import ButtonActionParticipate from '../../../components/rooms/ButtonActionParticipate';
import ButtonActionLikeRoom from "../../../components/rooms/ButtonActionLikeRoom";
import Header from "../../../layout/header/Header";
import ButtonEdit from "../../../components/App/Actions/ButtonEdit";

import ListBooks from "../../../app/components/books/ListBooks";
import DetailedInformation from "./parts/DetailedInformation";
import RoomsDetailMember from "./RoomsDetailMember";
import RoomsDetailWithoutMember from "./RoomsDetailWithoutMember";

const RoomsList = () => {

  const dispatch = useDispatch();
  
  const room = useSelector((state) => state.rooms.current);

  const { id } = useParams();
  const isMember = room.participante


  useEffect(() => {
    dispatch(fetchRoom(id));
  }, []);

  return (
    <>
      {
        <RoomsDetailWithoutMember />
      }
    </>
  );
}

export default RoomsList;
