import React, { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Dropzone from 'react-dropzone';

import Select from 'react-select';
import SelectAsync from 'react-select/async';

import { fetchBookById, fetchBookCategories, editBook, saveBook } from '../../../../redux/actions/books';
import { searchAuthors } from '../../../../redux/actions/authors';
import { searchPublisher } from '../../../../redux/actions/publishers';

import { UF_LIST, NoYesOptions } from '../../../../utils/constants';
import Header2 from "../../../layout/header/Header";

const BookForm = () => {
  const dispatch = useDispatch();

  const categories = useSelector((state) => state.books.categories);

  let { id } = useParams();
  let location = useLocation();

  const [logoUrl, setLogoUrl] = useState(undefined);
  const [image, setImage] = useState(undefined);

  const isEditMode = location.pathname.includes('editar');

  const {
    register,
    handleSubmit,
    control,
    reset,
    // getValues,
  } = useForm();

  // getValues();

  useEffect(() => {
    async function getData() {
      if (isEditMode) {
        let book = await dispatch(fetchBookById(id));

        const {
          capa_url,
          titulo,
          isbn,
          categoria,
          uf,
          autor,
          edicaoIndependente,
          editora,
          edicao,
          anoPublicacao,
          paginas,
          sinopse
        } = book;

        setLogoUrl(capa_url);

        reset({
          capa_url,
          titulo,
          isbn,
          categoria: categories.find((category) => category.value === categoria),
          ufPublicacaoLivro: UF_LIST.find((UF) => UF.value === uf),
          autor,
          edicaoIndependente: NoYesOptions.find((option) => option.value === edicaoIndependente),
          editora: editora[0],
          edicao,
          anoPublicacao,
          pagina: paginas,
          sinopse,
        });
      }
    }

    dispatch(fetchBookCategories());
    getData();
  }, []);

  const onChangeImage = (file) => {
    const reader = new FileReader();
    setImage(file);

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setLogoUrl(reader.result);
    }
  }

  const onSubmit = (form) => {
    const {
      titulo,
      isbn,
      categoria,
      ufPublicacaoLivro,
      autor,
      edicaoIndependente,
      editora,
      edicao,
      anoPublicacao,
      pagina,
      sinopse
    } = form;

    const formData = new FormData();

    if (image) {
      formData.append('capa', image, image.name);
    }

    formData.append('titulo', titulo);
    formData.append('isbn', isbn);

    if (categoria) {
      formData.append('categoria', categoria.value);
    }

    if (ufPublicacaoLivro) {
      formData.append('ufPublicacaoLivro', ufPublicacaoLivro.value);
    }

    if (edicaoIndependente) {
      formData.append('edicaoIndependente', edicaoIndependente.value);
    }

    if (editora) {
      formData.append('editora', editora.value);
    }

    if (autor && autor.length) {
      for (const option of autor) {
        formData.append('autor[]', option.value);
      }
    }

    if (edicao) {
      formData.append('edicao', edicao);
    }

    if (anoPublicacao) {
      formData.append('anoPublicacao', anoPublicacao);
    }

    if (pagina) {
      formData.append('pagina', pagina);
    }

    if (sinopse) {
      formData.append('sinopse', sinopse);
    }

    if (isEditMode) {
      dispatch(editBook(id, formData));
    } else {
      dispatch(saveBook(formData));
    }
  };

  const onChangeAuthors = async (value) => {
    return await dispatch(searchAuthors(value));
  }

  const onChangePublishers = async (value) => {
    return await dispatch(searchPublisher(value));
  }

  return (
    <>
      <Header2 title={isEditMode ? 'Editar Livro' : 'Cadastrar Livro'} />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-xl-12">
                <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="card-body d-flex justify-content-between align-items-end p-0">
                          <div className="form-group mb-0 w-100">
                            <label className="mont-fnt fw-600 font-xsss mb-2">Capa *</label>
                            <Dropzone onDrop={files => onChangeImage(files[0])}>
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div {...getRootProps()}>
                                    <div className="col-lg-12 mb-3">
                                      <div className="card mt-3 border-0">
                                        <input
                                          {...getInputProps()}
                                          name="logo"
                                          type="file"
                                          accept="image/png, image/gif, image/jpeg"
                                          onChange={(event) => onChangeImage(event.target.files[0])}
                                          className="form-control"
                                        />
                                        <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">

                                          {
                                            logoUrl ?
                                              (<div className="row justify-content-center">
                                                <div className="col-lg-4 text-center">
                                                  <figure className="avatar ms-auto me-auto mb-0 mt-2 w100">
                                                    <img
                                                      alt="logo-editora"
                                                      className="shadow-sm rounded-3 w-100"
                                                      src={logoUrl}
                                                    />
                                                  </figure>
                                                </div>
                                              </div>) :
                                              (
                                                <div>
                                                  <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                                  <span className="js-fileName">Arraste e solte ou clique para trocar</span>
                                                </div>
                                              )
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              )}
                            </ Dropzone>
                            <div className="col-lg-12 mt-3">
                              <div
                                onClick={() => {
                                  setLogoUrl(undefined);
                                  setImage(undefined);
                                }}
                                className="bg-gradiant-dark-green text-center text-white font-xsss fw-600 p-1 border-0 w175 rounded-3 d-inline-block"
                              >
                                Remover Imagem
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">Titulo *</label>
                          <input {...register("titulo", { required: true })}
                            type="text"
                            className="form-control"
                            placeholder="Titulo..."
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-fnt fw-600 font-xsss mb-2">ISBN *</label>
                          <input
                            {...register("isbn", { required: true })}
                            type="text"
                            className="form-control"
                            placeholder="ISBN..."
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-fnt fw-600 font-xsss mb-2">Categoria</label>
                          <Controller
                            name="categoria"
                            control={control}
                            render={({ field }) => <Select
                              {...field}
                              placeholder="Selecione uma categoria..."
                              options={categories}
                              isClearable
                            />}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-fnt fw-600 font-xsss mb-2">UF Publicação</label>
                          <Controller
                            name="ufPublicacaoLivro"
                            control={control}
                            render={({ field }) => <Select
                              {...field}
                              placeholder="Selecione uma UF..."
                              options={UF_LIST}
                              isClearable
                            />}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 mb-3">
                        <div className="form-group">
                          <label className="mont-fnt fw-600 font-xsss mb-2">Autor(es)</label>
                          <Controller
                            name="autor"
                            control={control}
                            render={({ field }) =>
                              <SelectAsync
                                {...field}
                                placeholder="procurar por autores..."
                                loadOptions={onChangeAuthors}
                                isMulti
                                noOptionsMessage={() => "Digite para procurar..."}
                              />}
                          />
                        </div>
                      </div>


                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-fnt fw-600 font-xsss mb-2">Edição Independente</label>
                          <Controller
                            name="edicaoIndependente"
                            control={control}
                            render={({ field }) => <Select
                              {...field}
                              placeholder="Selecione uma opção"
                              options={NoYesOptions}
                            />}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-fnt fw-600 font-xsss mb-2">Editora</label>
                          <Controller
                            name="editora"
                            control={control}
                            render={({ field }) => <SelectAsync
                              {...field}
                              placeholder="Editora..."
                              loadOptions={onChangePublishers}
                              noOptionsMessage={() => "Digite para procurar..."}
                            />}
                          />
                        </div>
                      </div>

                      <div className="col-lg-4 mb-3">
                        <div className="form-group">
                          <label className="mont-fnt fw-600 font-xsss mb-2">Edição</label>
                          <input
                            {...register("edicao")}
                            type="number"
                            className="form-control"
                            placeholder="Edição"
                          />
                        </div>
                      </div>


                      <div className="col-lg-4 mb-3">
                        <div className="form-group">
                          <label className="mont-fnt fw-600 font-xsss mb-2">Ano da Publicação</label>
                          <input
                            {...register("anoPublicacao")}
                            type="number"
                            className="form-control"
                            placeholder="Ano..."
                          />
                        </div>
                      </div>

                      <div className="col-lg-4 mb-3">
                        <div className="form-group">
                          <label className="mont-fnt fw-600 font-xsss mb-2">Número de Páginas</label>
                          <input
                            {...register("pagina")}
                            type="number"
                            className="form-control"
                            placeholder="Páginas..."
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 mb-3">
                        <label className="mont-font fw-600 font-xsss mb-2 text-dark">Sinopse</label>
                        <textarea {...register("sinopse")} className="form-control mb-0 p-3 h100 bg-greylight lh-16" rows="5" placeholder="Escreva sobre o Livro..." ></textarea>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <input
                        className="bg-gradiant-dark-green text-center text-white font-xsss fw-600 p-3 border-0 w175 rounded-3 d-inline-block"
                        type="submit"
                        value={isEditMode ? "Editar" : "Salvar"}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BookForm;
