export const SERVICE_HTTP_ADDRESS = 'https://api.livapp.com.br';
//export const SERVICE_HTTP_ADDRESS = 'http://api.livapp.xyz';
//export const SERVICE_HTTP_ADDRESS = 'http://localhost:3042';
//export const MOCK_SERVER_HTTP_ADDRESS = 'http://localhost:3042';

export const NoYesOptions = [
  { value: 0, label: 'Não' },
  { value: 1, label: 'Sim' },
];

export const UF_LIST = [
  { value: 'AC', label: 'Acre - AC' },
  { value: 'AL', label: 'Alagoas - AL' },
  { value: 'AP', label: 'Amapá - AP' },
  { value: 'AM', label: 'Amazonas - AM' },
  { value: 'BA', label: 'Bahia - BA' },
  { value: 'CE', label: 'Ceará - CE' },
  { value: 'DF', label: 'Distrito Federal - DF' },
  { value: 'ES', label: 'Espírito Santo - ES' },
  { value: 'GO', label: 'Goiás - ' },
  { value: 'MA', label: 'Maranhão - MA' },
  { value: 'MT', label: 'Mato Grosso - MT' },
  { value: 'MS', label: 'Mato Grosso do Sul - MS' },
  { value: 'MG', label: 'Minas Gerais - MG' },
  { value: 'PA', label: 'Pará - PA' },
  { value: 'PB', label: 'Paraíba - PB' },
  { value: 'PR', label: 'Paraná - PR' },
  { value: 'PE', label: 'Pernambuco - PE' },
  { value: 'PI', label: 'Piauí - PI' },
  { value: 'RJ', label: 'Rio de Janeiro - RJ' },
  { value: 'RN', label: 'Rio Grande do Norte - RN' },
  { value: 'RS', label: 'Rio Grande do Sul - RS' },
  { value: 'RO', label: 'Rondônia - RO' },
  { value: 'RR', label: 'Roraima - RR' },
  { value: 'SC', label: 'Santa Catarina - SC' },
  { value: 'SP', label: 'São Paulo - SP' },
  { value: 'SE', label: 'Sergipe - SE' },
  { value: 'TO', label: 'Tocantins - TO' },
];
