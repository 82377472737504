import { books } from '../reducers/books';
import { errorNotification, successNotification } from './communication';
import { updatePagination } from './pagination';
import history from '../../utils/history';
import * as data from '../../data/requests';

export const {
  updateBooks,
  updateCurrentBook,
  updateBooksCategories,
  resetBooks,
} = books.actions;

export const fetchBooks = query => async dispatch => {
  const queryParams = '?' + new URLSearchParams(query);

  try {
    const response = await data.get(`livro${queryParams}`);

    if (response.status === 200) {
      dispatch(updateBooks(response.data.data));
      dispatch(updatePagination(response.data.meta.pagination));
      return response.data.data.map(books => {
        return {
          value: books.id_livro,
          label: books.titulo,
        };
      });
    }
  } catch (error) {
    console.error('Erro ao retornar livros paginados!');
  }
};

export const fetchBookById = id => async dispatch => {
  try {
    const response = await data.get(`livro/${id}`);
    if (response.status === 200) {
      dispatch(updateCurrentBook(response.data.data));
      return response.data.data;
    }
  } catch (error) {
    dispatch(
      errorNotification('Erro ao retornar informação do livro selecionado!'),
    );
  }
};

export const fetchBookCategories = () => async dispatch => {
  try {
    const response = await data.get(`/categorias/livro`);
    if (response.status === 200) {
      dispatch(updateBooksCategories(response.data));
    }
  } catch (error) {
    console.error('Erro ao retornar categorias dos livros!');
  }
};

export const editBook = (id, formData) => async dispatch => {
  try {
    const response = await data.postForm(`livro/${id}`, formData);
    if (response.status === 200) {
      dispatch(updateCurrentBook(response.data.data));
      dispatch(successNotification('Livro editado com sucesso'));
    }
  } catch (error) {
    console.error('Erro ao editar livro!');
  }
};

export const saveBook = formData => async dispatch => {
  try {
    const response = await data.post(`livro`, formData);
    if (response.status === 201) {
      dispatch(updateCurrentBook(response.data.data));
      dispatch(successNotification('Livro salvo com sucesso'));

      if (response.data?.data) {
        history.push(`/admin/livros/editar/${response.data.data.id_livro}`);
      }
    }
  } catch (error) {
    console.error('Erro ao salvar livro!');
  }
};

export const resetBookList = () => dispatch => {
  dispatch(resetBooks());
};
