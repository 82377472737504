import React from 'react';
import { Link } from 'react-router-dom';

const ButtonInsert = ({ linkInsert }) => {

  return (
    <>
      {linkInsert &&
        <Link to={linkInsert} id="buttonFlutuante" >
            <img alt="Botão Flutuante" className="" src={"/theme/livapp/images/app/icones/add-button.svg"} />
        </Link>
      }
    </>
  )
}

export default ButtonInsert;


