import React from 'react';
import {Link, useParams} from "react-router-dom";

const SuccessfullyRegistered = () => {

  let { email } = useParams();  
  
  return (
    <div className="row p-0 m-0">
      <div
        className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
        style={{ backgroundImage: `url(/assets/images/livapp-compartilhe-suas-historias.png)` }}
      />
        <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
        <div className="card shadow-none border-0 ms-auto me-auto login-card">
          <div className="card-body rounded-0 text-left">
            <h2 className="fw-700 display1-size display2-md-size mb-4">
              Cadastro realizado com Sucesso!
            </h2>
            <p>Você já pode acessar o Liv. Em caso de dúvidas, entre em contato com o nosso suporte.</p>
            <div className="col-sm-12 p-0 text-left">
              <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                Já tem uma conta?
                <Link to="/auth" className="fw-700 ms-1">Faça Login</Link>
              </h6>
              <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32"><Link to="/auth/validar-registro" className="fw-700">Validar Registro</Link></h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessfullyRegistered;
