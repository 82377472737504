/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  content: [],
  memberCurrentClub: [],
  current: {},
};

const reducers = {
  updateClubs: (state, action) => {
    state.content = action.payload;
  },
  updateClubsPaginated: (state, action) => {
    state.content = [...state.content, ...action.payload];
  },
  updateCurrentClub: (state, action) => {
    state.current = action.payload;
  },
  updateMembersCurrentClub: (state, action) => {
    state.memberCurrentClub = action.payload;
  },
  resetClubs: (state, action) => {
    state.content = [];
  },
};

export const clubs = createSlice({
  name: 'clubs',
  initialState,
  reducers,
});
