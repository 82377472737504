import React, { createRef, useEffect, useState } from 'react';
import Dropzone from "react-dropzone";
import { fetchCategoriesClubs, fetchClubAdmin } from "../../../redux/actions/clubsAdm";

export default function DropzoneImageUpload({ type = {}, defaultImage = {}, ...props }) {

  const { image, setImage } = props;
  const { imageCover, setImageCover } = props;

  const [displayImage, setDisplayImage] = useState(undefined);
  const [defaultUserImage, setDefaultUserImage] = useState(undefined);


  const dropzoneRef = createRef();

  const resetImageDefault = () => {
    setDisplayImage(defaultUserImage)
  };

  const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point 
    if (dropzoneRef.current) {
      dropzoneRef.current.open()
    }
  };

  const onChangeImage = (file) => {
    const reader = new FileReader();

    if (type === "logo") {
      setImage(file);
    }

    if (type === "cover") {
      setImageCover(file);
    }

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setDisplayImage(reader.result);
    }
  }

  useEffect(() => {
    if (type === "logo") {
      setDefaultUserImage(image);
    }
    if (type === "cover") {
      setDefaultUserImage(imageCover);
    }
    if (defaultImage) {
      setDisplayImage(defaultImage)
    }
  }, [image, imageCover, defaultImage])

  return (
    <>
      <Dropzone ref={dropzoneRef} onDrop={files => onChangeImage(files[0])}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <div className="col-lg-12 mb-3">
                <div className="card mt-3 border-0">
                  <input
                    {...getInputProps()}
                    name="logo"
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    onChange={(event) => onChangeImage(event.target.files[0])}
                    className="form-control"
                  />
                  <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">

                    {
                      displayImage ?
                        (<div className="row justify-content-center">
                          <div className="col-lg-4 text-center">
                            <figure className="avatar ms-auto me-auto mb-0 mt-2 w100">
                              <img
                                alt="Imagem"
                                className="shadow-sm rounded-3 w-100"
                                src={displayImage}
                              />
                            </figure>
                          </div>
                        </div>) :
                        (
                          <div>
                            <i className="ti-cloud-down large-icon me-3 d-block"></i>
                            <span className="js-fileName">Arraste e solte ou clique para trocar</span>
                          </div>
                        )
                    }
                  </label>
                </div>
              </div>
            </div>
          </section>
        )}
      </ Dropzone>
      <div className="col-lg-12 mt-3">
        <div className="d-flex align-items-center pt-0 pe-4 pb-4">
          {displayImage ? (
            <button type="button" onClick={() => { setDisplayImage(undefined); }} className="border-0 p-2 lh-20 w100 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Remover</button>
          ) : (<button type="button" onClick={openDialog} className="border-0 p-2 lh-20 w100 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Adicionar</button>)
          }
          <button type="button" onClick={resetImageDefault} className="border-0 p-2 lh-20 w100 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl">Resetar</button>
        </div>
      </div>
    </>
  )
}
