import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'throttle-debounce';
import { fetchAuthors, fetchAuthorsPaginated, searchAuthors } from '../../../../redux/actions/authors';
import ListAuthors from '../../../components/list-authors/ListAuthors';
import Header2 from "../../../layout/header/Header";
import {fetchPublishersPaginated, searchPublisher} from "../../../../redux/actions/publishers";
import ButtonInsert from "../../../components/App/Actions/ButtonInsert";

const Authors = () => {
  const dispatch = useDispatch();
  const authors = useSelector((state) => state.authors.content);

  useEffect(() => {
    dispatch(fetchAuthorsPaginated())
  }, [dispatch]);

  const onSearch = debounce(800, (query) => {
    if (query.length > 2) {
      dispatch(searchAuthors(query));
    }

    if (query.length === 0) {
      dispatch(fetchAuthorsPaginated());
    }
  });

  const onFetchNextPage = async (page) => {
    const result = await dispatch(fetchAuthorsPaginated(page));
    return result;
  };

  return (
    <>
      <Header2 title={"Autores"} />
      <ButtonInsert linkInsert={"/admin/autores/adicionar"} />
      <div className="main-content InfiniteScroll right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-xl-12">
                <ListAuthors title="Autores" authors={authors} fetchNextPage={onFetchNextPage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Authors;
