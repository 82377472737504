import { configureStore } from '@reduxjs/toolkit';

import { communication } from './reducers/communication';
import { session } from './reducers/session';
import { users } from './reducers/users';
import { clubs } from './reducers/clubs';
import { clubsAdm } from './reducers/clubsAdm';
import { rooms } from './reducers/rooms';
import { roomsAdm } from './reducers/roomsAdm';
import { books } from './reducers/books';
import { authors } from './reducers/authors';
import { publishers } from './reducers/publishers';
import { publishersAdm } from './reducers/publishersAdm';
import { steps } from './reducers/steps';
import { feeds } from './reducers/feeds';
import { pagination } from './reducers/pagination';
import { home } from './reducers/home';

const store = configureStore({
  reducer: {
    communication: communication.reducer,
    session: session.reducer,
    users: users.reducer,
    clubs: clubs.reducer,
    clubsAdm: clubsAdm.reducer,
    rooms: rooms.reducer,
    roomsAdm: roomsAdm.reducer,
    books: books.reducer,
    authors: authors.reducer,
    publishers: publishers.reducer,
    publishersAdm: publishersAdm.reducer,
    steps: steps.reducer,
    feeds: feeds.reducer,
    pagination: pagination.reducer,
    home: home.reducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
