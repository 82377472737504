import React, { useState, useEffect, createRef } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import Dropzone from 'react-dropzone';
import * as data from '../../../../data/requests';

import { editPublisher } from '../../../../redux/actions/publishers';
import Header2 from "../../../layout/header/Header";

const PublishersEdit = () => {
  const dispatch = useDispatch();

  let { id } = useParams();

  const [logoUrl, setLogoUrl] = useState(undefined);
  const [image, setImage] = useState(undefined);

  // Specify the default image
  const [defaultUserImage, setDefaultUserImage] = useState(undefined);

  const resetImageDefault = () => {
    setLogoUrl(defaultUserImage)
  };

  const dropzoneRef = createRef();
  const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point 
    if (dropzoneRef.current) {
      dropzoneRef.current.open()
    }
  };

  const {
    register,
    handleSubmit,
    reset,
  } = useForm();

  useEffect(() => {
    async function fetchPublishers() {
      const response = await data.get(`editora/${id}`);
      const { nome, descricao, site, instagram, twitter, logo_url } = response.data?.data;

      reset({
        nome,
        descricao,
        site,
        instagram,
        twitter,
        logo_url,
      });

      setLogoUrl(logo_url);
      setDefaultUserImage(logo_url);
    }

    fetchPublishers();
  }, []);

  const onChangeImage = (file) => {
    const reader = new FileReader();
    setImage(file);

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setLogoUrl(reader.result);
    }
  }


  const onSubmit = (form) => {
    const { nome, descricao, site, instagram, twitter } = form;
    const formData = new FormData();
    formData.append('nome', nome);
    formData.append('descricao', descricao);
    formData.append('site', site);
    formData.append('instagram', instagram);
    formData.append('twitter', twitter);

    if (image) {
      formData.append('logo', image, image.name);
    }

    dispatch(editPublisher(formData, id));
  };

  return (
    <>
      <Header2 title={'Editar Editora'} />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom-custom">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-xl-12">
                <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">Nome</label>
                          <input {...register("nome")} type="text" className="form-control" required />
                        </div>
                      </div>

                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-fnt fw-600 font-xsss mb-2">Site</label>
                          <input
                            {...register("site")}
                            type="url"
                            className="form-control"
                            placeholder="http://exemplo.com" pattern="http(s?)://.*"
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 mb-3">
                        <label className="mont-font fw-600 font-xsss mb-2 text-dark">Descrição</label>
                        <textarea {...register("descricao")} className="form-control mb-0 p-3 h100 bg-greylight lh-16" rows="5" placeholder="Escreva sobre a editora..." ></textarea>
                      </div>

                      <div className="col-lg-12 mb-3">
                        <div className="card-body d-flex justify-content-between align-items-end p-0">
                          <div className="form-group mb-0 w-100">
                            <label className="mont-fnt fw-600 font-xsss mb-2">Logo</label>
                            <Dropzone ref={dropzoneRef} onDrop={files => onChangeImage(files[0])}>
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div {...getRootProps()}>
                                    <div className="col-lg-12 mb-3">
                                      <div className="card mt-3 border-0">
                                        <input
                                          {...getInputProps()}
                                          name="logo"
                                          type="file"
                                          accept="image/png, image/gif, image/jpeg"
                                          onChange={(event) => onChangeImage(event.target.files[0])}
                                          className="form-control"
                                        />
                                        <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">

                                          {
                                            logoUrl ?
                                              (<div className="row justify-content-center">
                                                <div className="col-lg-4 text-center">
                                                  <figure className="avatar ms-auto me-auto mb-0 mt-2 w100">
                                                    <img
                                                      alt="logo-editora"
                                                      className="shadow-sm rounded-3 w-100"
                                                      src={logoUrl}
                                                    />
                                                  </figure>
                                                </div>
                                              </div>) :
                                              (
                                                <div>
                                                  <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                                  <span className="js-fileName">Arraste e solte ou clique para trocar</span>
                                                </div>
                                              )
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              )}

                            </ Dropzone>
                            <div className="col-lg-12 mt-3">
                              <div className="d-flex align-items-center pt-0 pe-4 pb-4">
                                {logoUrl ? (
                                  <button type="button" onClick={() => { setLogoUrl(undefined); setImage(undefined); }} className="border-0 p-2 lh-20 w100 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Remover</button>
                                ) : (<button type="button" onClick={openDialog} className="border-0 p-2 lh-20 w100 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Adicionar</button>)
                                }
                                <button type="button" onClick={resetImageDefault} className="border-0 p-2 lh-20 w100 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl">Resetar</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-fnt fw-600 font-xsss mb-2">Instagram</label>
                          <input
                            {...register("instagram")}
                            type="url"
                            className="form-control"
                            placeholder="https://instagram.com/exemplo/" pattern="https://instagram.com/.*"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-fnt fw-600 font-xsss mb-2">Twitter</label>
                          <input
                            {...register("twitter")}
                            type="url"
                            className="form-control"
                            placeholder="https://twitter.com/exemplo" pattern="https://twitter.com/.*"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <input
                        className="bg-current text-center text-white font-xsss fw-600 p-3 border-0 w175 rounded-3 d-inline-block"
                        type="submit"
                        value="Editar"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PublishersEdit;
