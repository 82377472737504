import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Image, Container } from 'react-bootstrap';
import shareButton from '../../../../../../public/assets/images/icones/compartilhar.png';
import amazonLogo from '../../../../../../public/assets/images/icones/amazon.png';

const BookInfo = (props) => {

  const book = useSelector(state => state.books.current);

  return (
    <>
      <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
        <div className="card-body d-block p-4">
          <h1 className="fw-700 mb-3 text-grey-900 text-center">{book.titulo}</h1>
          <p className="fw-500 lh-24 font-xs mb-0 pb-4">{book.sinopse}</p>
        </div>
      </div>
    </>
  )
}

export default BookInfo;
