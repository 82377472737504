import React, {useState} from 'react';
import Header2 from "../../layout/header/Header";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ClubsAll from "./list/ClubsAll";
import ClubsSpotlight from "./list/ClubsSpotlight";
import ClubsMySelf from "./list/ClubsMySelf";
import ButtonInsert from "../../components/App/Actions/ButtonInsert";
import Header from "../../layout/header/Header";
import RoomsSpotlight from "../rooms/list/RoomsSpotlight";
import RoomsSearch from "../rooms/list/RoomsSearch";
import RoomsMySelf from "../rooms/list/RoomsMySelf";

const BooksMain = () => {

  const [defaultIndex, setDefaultIndex] = useState(0);

  return (
    <>
      <Header backPage={`/home`} titlePage={`Clubes`} />

      <Tabs defaultIndex={defaultIndex} id="TabRoom" className="pt-5">
        <TabList className="nav d-flex w-100 bg-primary-dark-green"  id="tab" role="tablist">
          <div className="container">
            <div className="row">
              <Tab className="col-4 text-center fw-700 font-xssss pt-3 pr-20 pl-20 ls-1 d-inline-block text-white pb-1">DESTAQUES</Tab>
              <Tab className="col-4 text-center fw-700 font-xssss pt-3 pr-20 pl-20 ls-1 d-inline-block text-white pb-1">PESQUISAS</Tab>
              <Tab className="col-4 text-center fw-700 font-xssss pt-3 pr-20 pl-20 ls-1 d-inline-block text-white pb-1">GESTÃO</Tab>
            </div>
          </div>
        </TabList>

        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0 tab-active">
              <div className="row">
                <div className="col-xl-12">
                  <ButtonInsert linkInsert={"/admin/clubes/adicionar"} linkHome={"/salas"} />
                  <TabPanel className="w-100"><ClubsSpotlight /></TabPanel>
                  <TabPanel className="w-100"><ClubsAll /></TabPanel>
                  <TabPanel className="w-100"><ClubsMySelf /></TabPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Tabs>
    </>
  );
}

export default BooksMain;
