/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  content: [],
  current: {},
};

const reducers = {
  updatePublishers: (state, action) => {
    state.content = action.payload;
  },
  updatePublishersPaginated: (state, action) => {
    state.content = [...state.content, ...action.payload];
  },
  updateCurrentPublisher: (state, action) => {
    state.current = action.payload;
  },
};

export const publishers = createSlice({
  name: 'publishers',
  initialState,
  reducers,
});
