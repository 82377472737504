import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import StepOne from './tabs/StepOne';
import StepTwo from './tabs/StepTwo';
import StepThree from './tabs/StepThree';
import Header2 from "../../../layout/header/Header";
import { useParams, useLocation } from "react-router-dom";

const RoomsAdd = (props) => {
  let defaultIndex = 0;

  const { id } = useParams();
  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const tab = query.get("tab");

  switch (tab) {
    case 'sala':
      defaultIndex = 0;
      break;
    case 'fase':
      defaultIndex = 1;
      break;
    case 'livro':
      defaultIndex = 2;
      break;

    default:
      break;
  }


  return (
    <>
      <Header2 title={'Criar Sala'} />
      <Tabs defaultIndex={defaultIndex} id="TabRoom" className="submenu nav nav-fill nav-default">
        <TabList className="nav nav-tabs h55 d-flex border-bottom-1 w-100" id="pills-tab" role="tablist">
          <Tab className="col-4 text-cente"><span className="fw-700 font-xssss link-tab pt-3 pr-20 pl-20 ls-1 d-inline-block">SALA</span></Tab>
          <Tab className="col-4 text-cente" disabled={!id}><span className="fw-700 font-xssss link-tab pt-3 pr-20 pl-20 ls-1 d-inline-block">FASE</span></Tab>
          <Tab className="col-4 text-cente" disabled={!id}><span className="fw-700 font-xssss link-tab pt-3 pr-20 pl-20 ls-1 d-inline-block">LIVROS</span></Tab>
        </TabList>
        <div className="main-content right-chat-active w-100">
          <div className="middle-sidebar-bottom-submenu">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12">
                  <div className="w-100 border-0 pb-7">
                    <TabPanel>
                      <StepOne />
                    </TabPanel>
                    <TabPanel>
                      <StepTwo />
                    </TabPanel>
                    <TabPanel>
                      <StepThree />
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Tabs>
    </>
  )
}

export default RoomsAdd;
