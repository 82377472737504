import { authors } from '../reducers/authors';
import { errorNotification, successNotification } from './communication';
import { updatePagination } from './pagination';
import history from '../../utils/history';
import * as data from '../../data/requests';

export const { updateAuthors, updateAuthorsPaginated, updateCurrentAuthor } =
  authors.actions;

export const fetchAuthors = () => async dispatch => {
  try {
    const response = await data.get('autor');
    if (response.status === 200) {
      dispatch(updateAuthors(response.data.data));
    }
  } catch (error) {
    console.error('Erro ao retornar autores cadastrados!');
  }
};

export const fetchAuthorById = id => async dispatch => {
  try {
    const response = await data.get(`autor/${id}`);
    if (response.status === 200) {
      dispatch(updateCurrentAuthor(response.data.data));
      return response.data.data;
    }
  } catch (error) {
    console.error('Erro ao retornar autor por ID!');
  }
};

export const fetchAuthorsPaginated = page => async dispatch => {
  try {
    let response;

    if (page) {
      response = await data.get(`autor?page=${page}`);
    } else {
      response = await data.get(`autor`);
    }

    if (response.status === 200) {
      dispatch(updateAuthorsPaginated(response.data.data));
      dispatch(updatePagination(response.data.meta.pagination));
    }
  } catch (error) {
    console.error('Erro ao retornar autores paginados!');
  }
};

export const searchAuthors = query => async dispatch => {
  try {
    const response = await data.get(`/autor?q=${query}`);
    if (response.status === 200) {
      dispatch(updateAuthors(response.data.data));
      return response.data.data.map(author => {
        return {
          value: author.id,
          label: author.pseudonimo,
        };
      });
    }
  } catch (error) {
    console.error('Erro ao procurar autores cadastrados!');
  }
};

export const editAuthor = (id, formData) => async dispatch => {
  try {
    const response = await data.postForm(`autor/${id}`, formData);
    if (response.status === 200) {
      dispatch(updateCurrentAuthor(response.data.data));
      dispatch(successNotification('Autor atualizado com Sucesso!'));
    }
  } catch (error) {
    console.error('Erro ao editar autor!');
  }
};

export const saveAuthor = formData => async dispatch => {
  try {
    const response = await data.post(`autor`, formData);
    if (response.status === 201) {
      dispatch(updateCurrentAuthor(response.data.data));
      history.push('/autores');
    }
  } catch (error) {
    console.error('Erro ao salvar autor!');
  }
};
