import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchAuthorById } from "../../../../redux/actions/authors";
import Header2 from "../../../layout/header/Header";
import {Tabs} from "react-tabs";

const BooksDetail = () => {

  const dispatch = useDispatch();
  const author = useSelector((state) => state.authors.current);

  let { id } = useParams();

  useEffect(() => {
    dispatch(fetchAuthorById(id));
  }, []);

  return (
    <>
      <Header2 title={"Autores"} />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-xl-12 col-xxl-12 col-lg-12">
                <div className="card d-block border-0 rounded-3 overflow-hidden p-4 shadow-xss mt-4">
                  <h2 className="fw-700 font-sm mb-3 mt-1 ps-1 mb-3">{author.pseudonimo}</h2>
                  <p className="font-xssss fw-500 lh-28 text-grey-600 mb-0 ps-2">{author.descricao}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BooksDetail;
