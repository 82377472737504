/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { changePassword } from '../../../../redux/actions/session';

const PasswordChange = () => {
  const dispatch = useDispatch();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (formData) => {
    const newData = {
      ...formData,
      email: query.get('email'),
      token: query.get('token'),
    };

    dispatch(changePassword(newData));
  };

  return (

    <div className="row p-0 m-0">
      <div
        className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
        style={{ backgroundImage: 'url("https://via.placeholder.com/800x950.png")' }}
      />

      <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
        <div className="card shadow-none border-0 ms-auto me-auto login-card">
          <div className="card-body rounded-0 text-left">
            <h2 className="fw-700 display1-size display2-md-size mb-4">
              Mude
              <br />
              sua senha
            </h2>
            <form onSubmit={handleSubmit(onSubmit)}>

              <div className="form-group icon-input mb-3">
                <i className="font-sm ti-lock text-grey-500 pe-0" />
                <input {...register('password', { required: true })} type="Password" className="style2-input ps-5 form-control text-grey-900 font-xss ls-3" placeholder="Insira uma senha nova" />
                {errors.password && <span>Por favor digite sua senha</span>}
              </div>
              <div className="form-group icon-input mb-3">
                <i className="font-sm ti-lock text-grey-500 pe-0" />
                <input {...register('password_confirmation', { required: true })} type="Password" className="style2-input ps-5 form-control text-grey-900 font-xss ls-3" placeholder="Confirme a senha nova" />
                {errors.password && <span>Por favor confirme sua senha</span>}
              </div>
              <div className="form-group mb-1">
                {/* Botão de enviar informações */}
                <input
                  className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0"
                  type="submit"
                  value="Mudar senha"
                />
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>

  );
};

export default PasswordChange;
