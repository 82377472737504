import React from 'react';
export default function DetailPageHeader ({background = {}, profileName = [], ...props}) {
  return (
    <div>
      <div className="card-body position-relative h150 bg-image-cover bg-image-center" style={{ backgroundImage: `url("${background}")` }}></div>
      <div className="card-body d-block pt-4 text-center">
        <h4 className="font-xs ls-1 fw-700 text-grey-900">{profileName}</h4>
      </div>
    </div>
  )
}
