import React from 'react';
import {Link} from "react-router-dom";
import moment from "moment";
import {useSelector} from "react-redux";

export default function DetailedInformation() {

  const room = useSelector((state) => state.rooms.current);

  function openWhatsapp(link) {
    window.open(link);
  }

  return (
    <>
      <div className="container">
        <div className="d-block px-4 pb-4">
          <p className="fw-500 text-dark lh-24 font-xssss mb-0 text-justify">{room.descricao}</p>
        </div>
        <div className="card-body border-top-xs d-flex">
          <i className="feather-users text-grey-500 me-3 font-lg" />
          <h4 className="fw-700 text-grey-900 font-xssss mt-0">Clube
            <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                        <Link to={`/clube/${room.clubeSelect?.slug}`}>{room.clubeSelect?.label}</Link>
                      </span>
          </h4>
        </div>
        {room.whatsapp &&
        <div className="card-body border-top-xs d-flex">
          <i className="feather-message-circle text-grey-500 me-3 font-lg"/>
          <h4 className="fw-700 text-grey-900 font-xssss mt-0">Whatsapp <span
            className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"> <Link onClick={ () => openWhatsapp(room.whatsapp)} target="_blank" rel="noopener noreferrer"> Acessar Grupo</Link></span>
          </h4>
        </div>
        }
        <div className="card-body d-flex pt-0">
          <i className="feather-calendar text-grey-500 me-3 font-lg" />
          <h4 className="fw-700 text-grey-900 font-xssss mt-0">Criação <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"> {moment(room.criacao).format('DD/MM/YYYY')}</span></h4>
        </div>
        <div className="card-body d-flex pt-0">
          <i className="feather-list text-grey-500 me-3 font-lg" />
          <h4 className="fw-700 text-grey-900 font-xssss mt-0">Categoria <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{room.nome_categoria}</span></h4>
        </div>
        <div className="card-body d-flex pt-0">
          <i className="feather-eye text-grey-500 me-3 font-lg" />
          <h4 className="fw-700 text-grey-900 font-xssss mt-0">Status <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{room.nome_status}</span></h4>
        </div>
      </div>
      <div className="card d-block border-0 rounded-3 overflow-hidden p-4 shadow-xss alert-success">
        <div className="container">
          <h2 className="fw-700 font-sm mb-3 mt-1 ps-1 text-success mb-4">Regras</h2>
          <h4 className="font-xssss fw-500 text-grey-600 mb-3 position-relative lh-24">
            {room.regras}
          </h4>
        </div>
      </div>
    </>
  )
}
