import React, {useEffect} from 'react';
import Header from "../../layout/header/Header";
import { Link } from "react-router-dom";
import HomeBooks from "./HomeBooks";
import HomeClubs from "./HomeClubs";
import HomeRooms from "./HomeRooms";
import {fetchHome} from "../../../redux/actions/home";
import {useDispatch, useSelector} from "react-redux";
import Appfooter from "../../layout/footer/Appfooter";

const Home = () => {

  const dispatch = useDispatch();

  const books = useSelector((state) => state.home.books);
  const rooms = useSelector((state) => state.home.rooms);
  const clubs = useSelector((state) => state.home.clubs);
  
  useEffect(() => {
    dispatch(fetchHome());
  }, [])


return (
  <>
    <Header titlePage={"Home"} />
    <div className="main-content right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left">
          <div className="row feed-body">
            <div className="col-xl-12 col-xxl-12 col-lg-12">
              <HomeBooks books={books} />
            </div>

            <div className="col-xl-12 col-xxl-12 col-lg-12">
              <HomeRooms rooms={rooms} />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Appfooter />
  </>
);
}

export default Home;






