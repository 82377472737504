import { feeds } from '../reducers/feeds';
import { errorNotification, successNotification } from './communication';
import history from '../../utils/history';
import * as data from '../../data/requests';

export const { updateFeeds } = feeds.actions;

export const fetchFeeds = idStep => async dispatch => {
  try {
    const response = await data.get('fase/' + idStep + '/feeds');
    if (response.status === 200) {
      dispatch(updateFeeds(response.data.data));
    }
  } catch (error) {
    console.error('Erro ao retornar feeds!');
  }
};

export const insertFeed = (idStep, params) => async dispatch => {
  try {
    const response = await data.post('feed', params);
    if (response.status === 201) {
      dispatch(fetchFeeds(idStep));
      dispatch(successNotification('Feed Adicionado com Sucesso!'));
      history.push(`/fase/${idStep}`);
    }
  } catch (error) {
    console.error(error.data.message);
  }
};

export const deleteFeed = (idFeed, idStep) => async dispatch => {
  try {
    const response = await data.del('feed/' + idFeed);
    if (response.status === 204) {
      dispatch(successNotification('Feed Removido com Sucesso!'));
    }
    dispatch(fetchFeeds(idStep));
  } catch (error) {
    console.error(error.data.message);
  }
};

export const createSuperLike = (idFeed, idStep) => async dispatch => {
  try {
    const response = await data.post('feed/' + idFeed + '/curtir');
    if (response.status === 201) {
      dispatch(successNotification('Super Curtida realizada com sucess!'));
    }
  } catch (error) {
    console.error(error.data.message);
  } finally {
    dispatch(fetchFeeds(idStep));
  }
};

export const deleteSuperLike = (idFeed, idStep) => async dispatch => {
  try {
    const response = await data.del('feed/' + idFeed + '/curtir');
    if (response.status === 201) {
      dispatch(successNotification('Super Curtida deletada com sucesso!'));
    }
  } catch (error) {
    dispatch(
      errorNotification(
        error.data?.message
          ? error.data?.message
          : 'Erro ao realizar procedimento!',
      ),
    );
  } finally {
    dispatch(fetchFeeds(idStep));
  }
};
