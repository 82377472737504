import React from 'react';
import BottomBar from './bottom-bar/BottomBar';
import { useSelector } from 'react-redux';
import {Redirect} from "react-router-dom";

const Layout = (props) => {
  const token = useSelector((state) => state.session.token);

  const renderMainContent = () => (
    <>
      {props.children}
    </>
  )

  return (
    <>
      {token ? renderMainContent() : props.children}
    </>
  )
}

export default Layout;
