import React, {useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from 'react-redux';
import DropzoneImageUpload from "../../../components/Form/DropzoneImageUpload";
import {saveClub} from "../../../../redux/actions/clubs";
import {fetchCategoriesClubs} from "../../../../redux/actions/clubsAdm";
import Header2 from "../../../layout/header/Header";
import Dropzone from "react-dropzone";
import Select from "react-select";
import {NoYesOptions, UF_LIST} from "../../../../utils/constants";
import SelectAsync from "react-select/async";

const ClubsAdd = () => {

  const dispatch = useDispatch();

  const [image, setImage] = React.useState([]);
  const [imageCover, setImageCover] = React.useState([]);
  const [imageDefaultCover, setimageDefaultCover] = React.useState([]);
  const [imageDefaultLogo, setimageDefaultLogo] = React.useState([]);

  const categoriesClub = useSelector((state) => state.clubsAdm.categories);

  const { register, handleSubmit, reset, formState: { errors } } = useForm({});

  useEffect(() => {
    dispatch(fetchCategoriesClubs())
    setimageDefaultCover("/assets/images/post.png");
    setimageDefaultLogo("/assets/images/user.png");
  }, [imageDefaultCover,imageDefaultLogo])




  const onSubmit = (form) => {
    const formData = new FormData();
    formData.append('nome', form.nome);
    formData.append('descricao', form.descricao);
    formData.append('categoria', form.categoria);
    if (image) {
      formData.append('imagemLogo', image, image.name);
    }

    if (imageCover) {
      formData.append('imagemPrincipal', imageCover, imageCover.name);
    }

    dispatch(saveClub(formData));
  };

  return (
    <>
      <Header2 title={'Cadastrar Clube'} />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-xl-12">
                <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">Nome</label>
                          <input
                            {...register('nome', { required: true })}
                            className={'form-control' + `${errors.nome ? " inputError" : ''}`}
                            autoComplete="off"
                            type="text"
                            placeholder="Nome"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">Categoria</label>
                          <select
                            {...register('categoria', { required: true })}
                            className={'form-control' + `${errors.categoria ? " inputError" : ''}`}
                            autoComplete="off"
                          >
                            <option value="">Selecione uma Categoria</option>
                            {categoriesClub.map((data, index) => (
                              <option key={index} value={data.id}>{data.nome}</option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-12 mb-3">
                        <label className="mont-font fw-600 font-xsss mb-2 text-dark">Descrição</label>
                        <textarea {...register("descricao")} className="form-control mb-0 p-3 h100 bg-greylight lh-16" rows="5" placeholder="Escreva sobre o clube..." ></textarea>
                      </div>

                      <div className="col-lg-12 mb-3">
                        <div className="card-body d-flex justify-content-between align-items-end p-0">
                          <div className="form-group mb-0 w-100">
                            <label className="mont-fnt fw-600 font-xsss mb-2">Logo</label>
                            <DropzoneImageUpload setImage={setImage} image={undefined} type={"logo"} defaultImage={imageDefaultLogo} />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 mb-3">
                        <div className="card-body d-flex justify-content-between align-items-end p-0">
                          <div className="form-group mb-0 w-100">
                            <label className="mont-fnt fw-600 font-xsss mb-2">Capa</label>
                            <DropzoneImageUpload setImageCover={setImageCover} imageCover={undefined} type={"cover"} defaultImage={imageDefaultCover} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <input
                        className="bg-gradiant-dark-green text-center text-white font-xsss fw-600 p-3 border-0 w175 rounded-3 d-inline-block"
                        type="submit"
                        value="Salvar"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ClubsAdd;
