import React from 'react';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

export default function DataListPublishers({ dataList, fetchNextPage }) {
  const pagination = useSelector((state) => state.pagination.content);

  return (
    <InfiniteScroll
      dataLength={dataList.length}
      next={() => fetchNextPage(pagination.current_page + 1)}
      hasMore={pagination.current_page < pagination.total_pages}
      endMessage={
        <p style={{ textAlign: 'center' }}>
          <b>Fim da listagem</b>
        </p>
      }
    >
      <div className="row ps-2 pe-1 items-grid">
        {dataList && dataList.map((publisher, index) => (
          <div key={index} className="col-md-4 col-sm-6 pe-2 ps-2" >
            <div className="ui-block">
              <div className="card w-100 border-0 mt-">
                <div className="card-image w-100 p-0 text-center bg-greylight rounded-3 mb-2">
                  <img src={publisher.logo_url || 'assets/images/publisher-temporary.png'} alt="editora" className="w-100 mt-0 mb-0 p-5" />
                </div>
                <div className="card-body w-100 p-0 text-center">
                  <h2 className="mt-2 mb-1 text-black fw-700 font-xsss lh-26">{publisher.nome}</h2>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </InfiniteScroll>
  )
}
