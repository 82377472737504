/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  content: [],
  current: {},
  members: [],
  categories: [],
};

const reducers = {
  updateRooms: (state, action) => {
    state.content = action.payload;
  },
  updateRoomsPaginated: (state, action) => {
    state.content = [...state.content, ...action.payload];
  },
  updateCurrentRoom: (state, action) => {
    state.current = action.payload;
  },
  updateMembersCurrentRoom: (state, action) => {
    state.members = action.payload;
  },
  updateRoomCategory: (state, action) => {
    state.categories = action.payload;
  },
  resetRooms: (state, action) => {
    state.content = [];
  },
};

export const rooms = createSlice({
  name: 'rooms',
  initialState,
  reducers,
});
