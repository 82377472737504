import React, {useState} from 'react';
import { useSelector } from "react-redux";
import ButtonActionDeleteFeed from '../Feeds/ButtonActionDeleteFeed';
import ButtonActionSuperLike from "./ButtonActionSuperLike";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

export default function Postview ({ idFeed = {}, idStep = {}, userFeed = [], time = {}, des = {}, avatar = {}, postimage = {}, postvideo = {}, roomUserAdmin = 0, superLike = false }) {

  const userLogged = useSelector((state) => state.session.user);
  const buttonSuperLike = ((userLogged.id === roomUserAdmin) && (userFeed.id !== roomUserAdmin));

  const [isOpen, setIsOpen] = useState(false);
  const photoIndex = () => this.setState(0);

  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
      <div className="card-body p-0 d-flex">
        <figure className="avatar me-3"><img src={`${avatar}`} alt="avater" className="shadow-sm rounded-circle w45" /></figure>
        <h4 className="fw-700 text-grey-900 font-xssss mt-1"> {userFeed.nome} <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"> {time}</span></h4>
        {(userLogged.id === userFeed.id) &&
          <ButtonActionDeleteFeed
            idFeed={idFeed}
            idStep={idStep}
          />
        }
      </div>
      {postvideo ?
        <div className="card-body p-0 mb-3 rounded-3 overflow-hidden uttam-die">
          <a href="/defaultvideo" className="video-btn">
            <video autoPlay loop className="float-right w-100">
              <source src={`assets/images/${postvideo}`} type="video/mp4" />
            </video>
          </a>
        </div>
        : ''}
      <div className="card-body p-0 me-lg-5">
        <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-2">{des}</p>
      </div>
      {postimage ?
        <div className="card-body d-block p-0 mb-3">
          <div className="row ps-2 pe-2">
            <div className="col-sm-12 p-1">
              <img onClick={() => setIsOpen(!isOpen)} src={`${postimage}`} className="rounded-3 imagemFeed" alt="imagem" />
              {isOpen && (
                <Lightbox
                  mainSrc={postimage}
                  onCloseRequest={() => setIsOpen(!isOpen)}
                />
              )}
            </div>
          </div>
        </div>
        : ''}
      <div className="card-body d-flex p-0">
        <div className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2">
          {(buttonSuperLike) &&
            <ButtonActionSuperLike idFeed={idFeed} idStep={idStep} isLike={superLike} />
          }
          {(superLike) ? (<span> Super Curtida recebida!</span>) : ('')}
        </div>
      </div>
    </div>
  )
}
