import React, {createRef, useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchLoggedUser, updateAvatarUser } from "../../../redux/actions/users";
import { useForm } from "react-hook-form";
import Dropzone from "react-dropzone";
import Header from "../../layout/header/Header";

const AvatarEdit = () => {
  const dropzoneRef = createRef();

  const { register, handleSubmit, errors } = useForm({});
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.session.user);
  const [logoUrl, setLogoUrl] = useState(undefined);
  const [image, setImage] = useState(undefined);

  const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point 
    if (dropzoneRef.current) {
      dropzoneRef.current.open()
    }
  };
  const onChangeImage = (file) => {
    const reader = new FileReader();
    setImage(file);

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setLogoUrl(reader.result);
    }
  }
  const onSubmit = () => {
    const formData = new FormData();
    formData.append('fileImagemAvatar', image, image.name);
     dispatch(updateAvatarUser(formData));
    setLogoUrl(undefined);
  };
  useEffect(() => {
    dispatch(fetchLoggedUser())
  }, [dispatch])

  return (
    <>
      <Header backPage={`/config`} titlePage={`Editar Avatar`} />
      <div className="main-content right-chat-active w-100">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12">
                <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                  <div className="row justify-content-center">
                    <div className="col-lg-4 text-center">
                      <figure className="avatar ms-auto me-auto mb-0 mt-2 w100">
                        <img src={profile.avatar === null ? '/assets/images/app/default/user_avatar_padrao.png' : profile.avatar_url} alt="Avatar" className="shadow-sm rounded-3 w-100" />
                      </figure>
                      <h2 className="fw-700 font-sm text-grey-900 mt-3">{profile.name}</h2>
                    </div>
                  </div>
                  <form action="#"  onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="card-body d-flex justify-content-between align-items-end p-0">
                          <div className="form-group mb-0 w-100">
                            <label className="mont-fnt fw-600 font-xsss mb-2">Avatar *</label>
                            <Dropzone ref={dropzoneRef} onDrop={files => onChangeImage(files[0])}>
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div {...getRootProps()}>
                                    <div className="col-lg-12 mb-3">
                                      <div className="card mt-3 border-0">
                                        <input
                                          {...getInputProps()}
                                          name="logo"
                                          type="file"
                                          accept="image/png, image/gif, image/jpeg"
                                          onChange={(event) => onChangeImage(event.target.files[0])}
                                          className="form-control"
                                        />
                                        <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">
                                          {
                                            logoUrl ?
                                              (<div className="row justify-content-center">
                                                <div className="col-lg-4 text-center">
                                                  <figure className="avatar ms-auto me-auto mb-0 mt-2 w100">
                                                    <img
                                                      alt="logo-editora"
                                                      className="shadow-sm rounded-3 w-100"
                                                      src={logoUrl}
                                                    />
                                                  </figure>
                                                </div>
                                              </div>) :
                                              (
                                                <div>
                                                  <i className="ti-cloud-down large-icon me-3 d-block" />
                                                  <span className="js-fileName">Arraste e solte ou clique para trocar</span>
                                                </div>
                                              )
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              )}
                            </ Dropzone>
                            <div className="col-lg-12 mt-3">
                              <div className="d-flex align-items-center pt-0 pe-4 pb-4">
                                {image ? (
                                  <button type="button" onClick={() => { setImage(undefined); }} className="border-0 p-2 lh-20 w100 bg-gradiant-dark-green me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Remover</button>
                                ) : (<button type="button" onClick={openDialog} className="border-0 p-2 lh-20 w100 bg-gradiant-dark-green me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Adicionar</button>)
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row pt-4">
                      <div className="col-lg-12 mb-0">
                        <button className="bg-gradiant-dark-green text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">SALVAR</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AvatarEdit;
