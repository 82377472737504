/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  content: [],
  current: {},
  categories: [],
};

const reducers = {
  updateBooks: (state, action) => {
    state.content = [...state.content, ...action.payload];
  },
  updateCurrentBook: (state, action) => {
    state.current = action.payload;
  },
  updateBooksCategories: (state, action) => {
    state.categories = action.payload.map(category => {
      return {
        value: category.id_categoria_livro,
        label: category.nome_categoria,
      };
    });
  },
  resetBooks: (state, action) => {
    state.content = [];
  },
};

export const books = createSlice({
  name: 'books',
  initialState,
  reducers,
});
