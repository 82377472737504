import React from 'react';
import {useDispatch} from "react-redux";
import {createSuperLike, deleteSuperLike} from "../../../redux/actions/feeds";

export default function ButtonActionSuperLike ({idFeed = 0, idStep = 0, isLike = false}) {

  const dispatch = useDispatch();

  const onActionSuperLike = (idFeed, idStep, isLike) => {
      dispatch(createSuperLike(idFeed, idStep))
  }

  const onActionDeleteSuperLike = (idFeed, idStep, isLike) => {
    dispatch(deleteSuperLike(idFeed, idStep))
  }
  
  return (
    <>
      { isLike ? (
          <i className="feather-thumbs-up text-white bg-primary-green btn-round-md font-xss me-2" title="Retirar Super Curtida" onClick={() => onActionDeleteSuperLike(idFeed, idStep)} />
      ): (
          <i className="feather-thumbs-up text-dark bg-lightgreen btn-round-md font-xss me-2" title="Super Like" onClick={() => onActionSuperLike(idFeed, idStep)} />
      )}
    </>
  )
}
