import React, { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';

import { fetchRoomsCategory, saveRoom, editRoom } from '../../../../../redux/actions/rooms';
import { searchUserClubs, fetchClubLoggedUserOptions } from '../../../../../redux/actions/clubs';
import { fetchRoomById } from "../../../../../redux/actions/roomsAdm";

import Dropzone from 'react-dropzone';
import ButtonPublishRoom from "../../../../components/rooms/ButtonPublishRoom";
import { typeRooms } from "../../../../../utils/enum/rooms";

const StepOne = () => {

  const dispatch = useDispatch();
  const [logoUrl, setLogoUrl] = useState(undefined);
  const [image, setImage] = useState(undefined);
  const [statusRoom, setStatusRoom] = useState(0);

  let location = useLocation();
  let { id } = useParams();

  const categories = useSelector((state) => state.rooms.categories);
  const clubs = useSelector((state) => state.clubsAdm.listClubsSelectAdmin);

  const isEditMode = location.pathname.includes('editar') || id;

  const {
    register,
    control,
    handleSubmit,
    reset
  } = useForm();

  useEffect(() => {
    async function getData() {
      await dispatch(fetchRoomsCategory());
      await dispatch(fetchClubLoggedUserOptions());

      if (isEditMode) {
        let room = await dispatch(fetchRoomById(id));
        if (room) {
          const {
            capa_url,
            nome,
            clubeSelect,
            categoriaSelect,
            tipoSelect,
            id_categoria,
            descricao,
            regras,
          } = room;

          setLogoUrl(capa_url);
          setStatusRoom(room.status);
          //setIdRoom(room.id)

          reset({
            capa_url,
            nome,
            clube: clubeSelect,
            categoria: categoriaSelect,
            descricao,
            regras,
            tipo: tipoSelect
          });
        }
      }
    }

    getData();
  }, []);

  const onSubmit = (form) => {
    const {
      nome,
      clube,
      categoria,
      descricao,
      regras,
      tipo
    } = form;

    //console.log(form);

    const formData = new FormData();

    if (image) {
      formData.append('imagemPrincipal', image, image.name);
    }

    if (nome) {
      formData.append('nome', nome);
    }

    if (clube) {
      formData.append('clube', clube.value);
    }

    if (categoria) {
      formData.append('categoria', categoria.value);
    }

    if (descricao) {
      formData.append('descricao', descricao);
    }

    if (regras) {
      formData.append('regras', regras);
    }

    if (tipo) {
      formData.append('tipo', tipo.value);
    }


    if (isEditMode) {
      dispatch(editRoom(formData, id));
    } else {
      dispatch(saveRoom(formData));
    }
  };

  const onChangeImage = (file) => {
    const reader = new FileReader();
    setImage(file);

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setLogoUrl(reader.result);
    }
  }

  const onChangeClubs = async (value) => {
    return await dispatch(searchUserClubs(value));
  }


  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-0" action="#">
      <div className="row">
        <div className="col-lg-12 mb-3">
          <label className="mont-fnt fw-600 font-xsss mb-2">Capa *</label>
          <Dropzone onDrop={files => onChangeImage(files[0])}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <div className="col-lg-12 mb-3">
                    <div className="card mt-3 border-0">
                      <input
                        {...getInputProps()}
                        name="imagemPrincipal"
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={(event) => onChangeImage(event.target.files[0])}
                        className="form-control"
                      />
                      <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">

                        {
                          logoUrl ?
                            (<div className="row justify-content-center">
                              <div className="col-lg-4 text-center">
                                <figure className="avatar ms-auto me-auto mb-0 mt-2 w100">
                                  <img
                                    alt="logo-editora"
                                    className="shadow-sm rounded-3 w-100"
                                    src={logoUrl}
                                  />
                                </figure>
                              </div>
                            </div>) :
                            (
                              <div>
                                <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                <span className="js-fileName">Arraste e solte ou clique para trocar</span>
                              </div>
                            )
                        }
                      </label>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </ Dropzone>
        </div>
        <div className="col-lg-12 mb-3">
          <div className="form-group">
            <label className="mont-font fw-600 font-xsss mb-2">Título da Sala</label>
            <input {...register("nome", { required: true })} type="text" className="form-control" placeholder="Digite o título da sala" />
          </div>
        </div>
        <div className="col-lg-6 mb-3">
          <div className="form-group">
            <label className="mont-font fw-600 font-xsss mb-2">Clube</label>
            <Controller
              name="clube"
              control={control}
              render={({ field }) =>
                <Select
                  {...field}
                  placeholder="Digite para procurar seus clubes..."
                  options={clubs}
                  isClearable
                />}
            />
          </div>
        </div>
        <div className="col-lg-6 mb-3">
          <div className="form-group">
            <label className="mont-font fw-600 font-xsss mb-2">Categoria</label>
            <Controller
              name="categoria"
              control={control}
              render={({ field }) => <Select
                {...field}
                placeholder="Selecione uma categoria..."
                options={categories}
              />}
            />
          </div>
        </div>
        <div className="col-lg-6 mb-3">
          <div className="form-group">
            <label className="mont-fnt fw-600 font-xsss mb-2">Tipo de Sala</label>
            <Controller
              name="tipo"
              control={control}
              render={({ field }) => <Select
                {...field}
                placeholder="Selecione um tipo de Sala"
                options={typeRooms}
              />}
            />
          </div>
        </div>
        <div className="col-lg-12 mb-3">
          <label className="mont-font fw-600 font-xsss mb-2 text-dark">Descrição</label>
          <textarea {...register("descricao")} className="form-control mb-0 p-3 h100 bg-greylight lh-16" rows="5" placeholder="Digite a descrição da Sala..." ></textarea>
        </div>
        <div className="col-lg-12 mb-3">
          <label className="mont-font fw-600 font-xsss mb-2 text-dark">Regras</label>
          <textarea {...register("regras")} className="form-control mb-0 p-3 h100 bg-greylight lh-16" rows="5" placeholder="Digite as regras da Sala..." ></textarea>
        </div>
        <div className="col-lg-12">
          <input
            className="bg-gradiant-dark-green text-center text-white font-xsss fw-600 p-3 border-0 w175 rounded-3 d-inline-block"
            type="submit"
            value={isEditMode ? "Salvar" : "Salvar"}
          />
          {isEditMode &&
            <ButtonPublishRoom
              idRoom={id}
              currentStatus={statusRoom}
            />
          }
        </div>
      </div>
    </form>
  );
}
export default StepOne;
