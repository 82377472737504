import React from 'react';
import {exitRoom, likeRoom, participateRoom} from "../../../redux/actions/rooms";
import {useDispatch} from "react-redux";

export default function ButtonActionLikeRoom ({idRoom = {}, slugRoom, boolFollow = undefined, style= {}, ...props}) {

  const dispatch = useDispatch();
  
  const onClickLikeRoom = (idRoom, slugRoom, boolAction) => {
    dispatch(likeRoom(idRoom, slugRoom, boolAction));
  };

  return (
    <div>
      {boolFollow ? (
        <button
          type="button"
          className={'border-0 bg-danger ' + `${style}`}
          title={"Parar de Curtir"}
          onClick={() => onClickLikeRoom(idRoom, slugRoom, false)}>
          <i className="feather-heart font-md text-white" />
        </button>
      ) : (
        <button
          type="button"
          className={'border-0 bg-sucess' + `${style}`}
          title={"Curtir"}
          onClick={() => onClickLikeRoom(idRoom, slugRoom, true)}>
          <i className="feather-heart font-md text-dark" />
        </button>
      )}
    </div>
  )
}
