/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: JSON.parse(localStorage.getItem('user')) || null,
  token: localStorage.getItem('token') || null,
};

const reducers = {
  login: (state, action) => {
    localStorage.setItem('user', JSON.stringify(action.payload));
    state.user = action.payload;
  },
  logout: state => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    state.user = null;
    state.token = null;
  },
  updateToken: (state, action) => {
    localStorage.setItem('token', action.payload);
    state.token = action.payload;
  },
};

export const session = createSlice({
  name: 'session',
  initialState,
  reducers,
});
