import React from 'react';
import axios from 'axios';
import store from '../redux/store';

import {
  incrementLoading,
  decrementLoading,
  errorNotification,
} from '../redux/actions/communication';

import { SERVICE_HTTP_ADDRESS } from '../utils/constants';

/* -------------------------------------------------------------------------- */
/*                         HTTP Requests Configuration                        */
/* -------------------------------------------------------------------------- */

/* ----------------------------- Default Values ----------------------------- */

axios.defaults.baseURL = SERVICE_HTTP_ADDRESS;
axios.defaults.timeout = 30000;
axios.defaults.headers.common.Authorization = ` bearer ${
  window.localStorage.getItem('token') || null
}`;

export function updateDefaultToken(token) {
  axios.defaults.headers.common.Authorization = ` bearer ${token}`;
}

/* axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'X-Requested-With';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common.AccessControlAllowOrigin = '*';
axios.defaults.headers.common.AccessControlAllowMethods = 'GET, POST, PATCH, PUT, DELETE, OPTIONS';
axios.defaults.headers.common.AccessControlAllowHeaders = 'Origin, Content-Type, X-Auth-Token';
axios.defaults.headers.common.ContentType = 'application/json'; */

/* ------------------------------ Interceptors ------------------------------ */

axios.interceptors.request.use(config => {
  store.dispatch(incrementLoading());
  return config;
});

axios.interceptors.response.use(
  response => {
    store.dispatch(decrementLoading());
    return response;
  },
  error => {
    store.dispatch(decrementLoading());

    if (error.response?.data?.errors) {
      const reducer = (previousValue, currentValue) =>
        previousValue + ' ' + currentValue;
      const keys = Object.keys(error.response.data.errors);

      let allErrors = [];

      for (const key of keys) {
        const errorList = error.response.data.errors[key];
        const errorString = errorList.reduce(reducer);

        allErrors.push(errorString);
      }

      store.dispatch(
        errorNotification(
          allErrors.map(e => (
            <>
              {e}
              {allErrors.length > 1 && (
                <>
                  <br />
                  <br />
                </>
              )}
            </>
          )),
        ),
      );
      return Promise.reject(error);
    } else if (error.response?.data?.message) {
      store.dispatch(errorNotification(error.response.data.message));
    } else {
      store.dispatch(errorNotification('Erro ao realizar requisição'));
    }
  },
);

/* ------------------------------ Http Requests ----------------------------- */

export function get(url, id) {
  const urlTemplate = (id && `${url}/${id}`) || url;

  return new Promise((resolve, reject) =>
    axios
      .get(urlTemplate)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error.response);
      }),
  );
}

export function post(url, params) {
  return new Promise((resolve, reject) =>
    axios
      .post(url, params)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error.response);
      }),
  );
}

export function postForm(url, formData) {
  formData.append('_method', 'PUT');

  return new Promise((resolve, reject) =>
    axios
      .post(url, formData)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error.response);
      }),
  );
}

export function put(url, params) {
  return new Promise((resolve, reject) =>
    axios
      .put(url, params)
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      }),
  );
}

export function del(url) {
  return new Promise((resolve, reject) =>
    axios
      .delete(url)
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      }),
  );
}
