/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';

const initialState = {
  loadingStatus: 0,
  notifications: [],
  successMessages: [],
  errorMessages: [],
};

const reducers = {
  incrementLoading: state => {
    state.loadingStatus += 1;
  },
  decrementLoading: state => {
    state.loadingStatus -= 1;
  },
  notification: (state, action) => {
    state.notifications.push(action.payload);
    toast(action.payload);
  },
  successNotification: (state, action) => {
    state.successMessages.push(action.payload);
    toast.success(action.payload);
  },
  errorNotification: (state, action) => {
    state.errorMessages.push(action.payload);
    toast.error(action.payload);
  },
};

export const communication = createSlice({
  name: 'communication',
  initialState,
  reducers,
});
