/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  content: [],
};

const reducers = {
  updateUsers: (state, action) => {
    state.content = action.payload;
  },
};

export const users = createSlice({
  name: 'users',
  initialState,
  reducers,
});
