import React from 'react';
import {Link} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import {updateEmaildUser} from "../../../redux/actions/users";
import Header2 from "../../layout/header/Header";
import {ReactComponent as Usuario} from "../../../../public/assets/images/icones/username.svg";
import {ReactComponent as Avatar} from "../../../../public/assets/images/icones/avatar.svg";
import {ReactComponent as Senha} from "../../../../public/assets/images/icones/cadeado.svg";
import {ReactComponent as Email} from "../../../../public/assets/images/icones/email.svg";
import {logout} from "../../../redux/actions/session";
import Dropzone from "react-dropzone";
import Select from "react-select";
import {NoYesOptions, UF_LIST} from "../../../utils/constants";
import SelectAsync from "react-select/async";
import Header from "../../layout/header/Header";

const EmailEdit = () => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm({});
  
  const dispatch = useDispatch();

  const onSubmit = (formData) => {
    dispatch(updateEmaildUser(formData));
    reset({
      password: '',
      email: '',
      email_confirmation: ''
    })
  };

  return (
    <>
      <Header backPage={`/config`} titlePage={`Editar E-mail`} />
      <div className="main-content right-chat-active w-100">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div>
              <div className="col-xl-12">
                <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="form-gorup">
                          <label className="mont-font fw-600 font-xssss">Sua Senha</label>
                          <input
                            {...register('password', { required: true })}
                            className={'form-control' + `${errors.password ? " inputError" : ''}`}
                            autoComplete="off"
                            type="password"
                            placeholder="Sua Senha"
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 mb-3">
                        <div className="form-gorup">
                          <label className="mont-font fw-600 font-xssss">Novo E-mail</label>
                          <input
                            {...register('email', { required: true })}
                            className={'form-control' + `${errors.email ? " inputError" : ''}`}
                            autoComplete="off"
                            type="text"
                            placeholder="Sua Senha"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 mb-3">
                        <div className="form-gorup">
                          <label className="mont-font fw-600 font-xssss">Confirmar novo E-mail</label>
                          <input
                            {...register('email_confirmation', { required: true })}
                            className={'form-control' + `${errors.email_confirmation ? " inputError" : ''}`}
                            autoComplete="off"
                            type="text"
                            placeholder="Confirme o Novo E-mail"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 mb-0">
                        <button className="bg-gradiant-dark-green text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">SALVAR</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EmailEdit;
