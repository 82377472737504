import React from 'react';
import Slider from "react-slick";
import {Link} from "react-router-dom";

export default function ListBooks({ books = []}) {

  function openAmazon(stringSearch) {
    window.open("https://www.amazon.com.br/gp/search?ie=UTF8&tag=guiacapixabanet-20&linkCode=ur2&linkId=2170ede2699e44c464dc8a2c74923725&camp=1789&creative=9325&index=books&keywords="+stringSearch);
  }

  return (
    <div className="container mb-3">
        {books.map((book, index) => (
          <div key={`book_${index}`} className="col-md-3 col-xss-6 pe-2 ps-2">
            <div className="card w-100 p-0 hover-card shadow-xss border-0 rounded-3 overflow-hidden me-1">
              <div className="card-image w-100 mb-3">
                <Link to={`livro/${book.slug_livro}`} className="position-relative d-block">
                  <img src={`http://api.livapp.xyz/imagem/book-medium/${book.capa_livro}`} alt="hotel" className="w-100" />
                </Link>
              </div>
              <div className="card-body pt-0 w-100  align-items-center justify-content-center text-center">
                <Link to={''} onClick={ () => openAmazon(book.titulo_livro) } className="btn-round-md alert-dark text-white d-inline-block mt-0 me-3 bg-gradiant-dark-green">
                  <i className="ti-shopping-cart font-xs" />
                </Link>
                <Link to={`livro/${book.slug_livro}`} className="btn-round-md alert-dark text-white d-inline-block mt-0 bg-gradiant-dark-green">
                  <i className="ti-eye font-xs" />
                </Link>
                <div className="clearfix" />
              </div>
            </div>

          </div>
        ))}
    </div>
  )
}

