import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteBookInRoom } from "../../../../redux/actions/roomsAdm";


export default function DataListBooksInRooms({ idRoom = 0, books = [], actionDelete }) {
  const dispatch = useDispatch();

  const onDeleteBook = (idBook) => {
    dispatch(deleteBookInRoom(idRoom, idBook));
  }

  return (
    <>
      {books.map((book, index) => (
        <div className="d-flex align-items-center">
          <div key={`booksInRooms_${index + 1}`} className="card-body bg-transparent-card d-flex p-3 bg-greylight ms-3 me-3 rounded-3 mb-3">
            <figure className="avatar me-2 mb-0">
              <img
                src={book.capa_url}
                alt="livro"
                className="shadow-sm w45"
              />
            </figure>
            <h4 className="fw-700 text-grey-900 font-xssss mt-2">
              {book.titulo_livro}{' '}
            </h4>
            <div className="btn-round-sm ms-auto mt-2">
              <i onClick={() => onDeleteBook(book.id_livro)} className="feather-x-circle fw-400 ls-3 font-lg mt-2 mb-1" />
            </div>
          </div>
        </div>
      ))}
    </>
  )
}
