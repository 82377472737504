import React, { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import {useLocation, useParams} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";

import { getStepsTypes, addStep, getConfigTypeStep } from '../../../../redux/actions/steps';

import Header from "../../../layout/header/Header";
import SubHeader from "../../../layout/header/SubHeader";
import history from "../../../../utils/history";
import Pagetitle from "../../../layout/header/Pagetitle";
import Warning from "../../../core/Warning";
const StepDetails = () => {
  
  const dispatch = useDispatch();

  const configTypeStep = useSelector((state) => state.steps.currentTypeStep);

  /* Obter os dados da URL */ 
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  let idRoom = query.get("room");
  let idTypeStep = query.get("typeStep");


  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const {
    register,
    control,
    handleSubmit,
  } = useForm();

  /* Redireciona a navegação para e etapa seguinte */
  const backPage = () => {
    history.push("/admin/fases/criar/escolher-tipo-fase?room="+idRoom+'&typeStep='+idTypeStep)
  }

  useEffect(() => {
    
    /* Obter os dados de Configuração do Tipo de Fase Escolhido */
    async function getData(id) {
      await dispatch(getConfigTypeStep(id));
    }

    getData(idTypeStep);
  }, []);

  const onSubmit = (form) => {

    const {
      nome,
      descricao,
    } = form;

    const formData = new FormData();

    formData.append('nome', nome);
    formData.append('descricao', descricao);
    formData.append('inicio', (moment(startDate).format('YYYY-MM-DD')));
    formData.append('fim', (moment(endDate).format('YYYY-MM-DD')));
    formData.append('tipo', idTypeStep);
    formData.append('sala', idRoom);

    dispatch(addStep(formData, idRoom));
  }

  return (
    <>
      <Header title={'Adicionar Fase'} />
      <div className="main-content right-chat-active no-bottom-bar">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-xl-12">
                <Pagetitle title="Passo 2" subTitle="Insira as informações da fase" />

                {configTypeStep.aviso &&
                  <Warning message={configTypeStep.aviso} />
                }

                <form onSubmit={handleSubmit(onSubmit)}>

                  <div className="row">
                    {configTypeStep.ativarDataInicio &&
                    <div className="col-lg-12 mb-3">
                      <div className="form-gorup">
                        <label className="mont-font fw-600 font-xssss text-dark required">Data de início</label>
                        <DatePicker
                          dateFormat="d/M/Y"
                          className="form-control"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />
                      </div>
                    </div>
                    }
                    {configTypeStep.ativarDataFim &&
                    <div className="col-lg-12 mb-3">
                      <div className="form-gorup">
                        <label className="mont-font fw-600 font-xssss text-dark required">Data fim</label>
                        <DatePicker
                          dateFormat="d/M/Y"
                          className="form-control"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                        />
                      </div>
                    </div>
                    }
                  </div>
                  
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="form-gorup">
                        <label className="mont-font fw-600 font-xssss text-dark required">Título</label>
                        <input {...register("nome", { required: true })}
                           type="text"
                           className="form-control"
                           placeholder="Digite o título da fase..."
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <div className="form-gorup">
                        <label className="mont-font fw-600 font-xssss text-dark required">Descrição</label>
                        <textarea
                          {...register("descricao", { required: true })}
                          className={'h100 bor-0 w-100 p-2 font-xssss text-dark-500 fw-500 border-light-md theme-dark-bg'}
                          cols="30"
                          rows="10"
                          placeholder="Escreva um pouco sobre a fase..."
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 mb-0">
                      <button className="rounded-3 bg-primary-dark-green mb-2 mt-4 p-3 w-100 fw-600 fw-700 text-center font-xssss mont-font text-uppercase ls-3 text-white d-block border-0">CRIAR FASE</button>
                      <button onClick={() => backPage()}  className="rounded-3 bg-primary-dark-green mb-2 mt-4 p-3 w-100 fw-600 fw-700 text-center font-xssss mont-font text-uppercase ls-3 text-white d-block border-0">VOLTAR</button>
                    </div>
                  </div>
                  
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StepDetails;
