import React, { Component } from 'react';
import Slider from "react-slick";
import { Link } from "react-router-dom";

class HomeClubs extends Component {
  render() {
    const storysettings = {
      arrows: true,
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: 1,
      centerMode: false,
      variableWidth: false
    };

    const clubs = this.props.clubs;

    return (
      <>
        <Slider key={'slideClubs'} {...storysettings} className={"w-100"}>
          {clubs.map((value, index) => (
            <div key={index} className="w-100 col-md-12 col-sm-12 pe-2 ps-2">
              <Link to={`clube/${value.slug}`}>
                <div className="">
                  <div className="card-body position-relative h100 bg-image-cover bg-image-center" style={{ backgroundImage: `url("${value.imagemUrl}")` }} />
                  <div className="card-body d-block w-100 pl-10 pe-4 pb-4 pt-0 text-left position-relative">
                    <figure className="avatar position-absolute w75 z-index-1 left-15" style={{ marginTop: `-40px` }}>
                      <img src={`${value.logoUrl}`} alt="logo" className="float-right p-1 bg-white rounded-circle w-100 " />
                    </figure>
                    <div className="clearfix" />
                    <h4 className="fw-700 font-xsss mt-3 mb-1">{value.nome}</h4>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      </>

    );
  }
}

export default HomeClubs;
