import React from 'react';
import ReactDOM from 'react-dom';
import Toaster from './main/components/ToasterCustom/ToasterCustom';
import { Provider } from 'react-redux';
import history from './utils/history';

/* ------------------------------- Redux Store ------------------------------ */
import store from './redux/store';

/* ------------------------------- Components ------------------------------- */
import App from './main/App';
import Loading from './main/components/loading/Loading';

/* --------------------------------- Styles --------------------------------- */
import './main.scss';
import 'react-tabs/style/react-tabs.css';


import { Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

serviceWorker.register();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Loading />
      <Toaster />
      <Router history={history} basename={'/'}>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
