import React, { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";

import { getStepsTypes, addStep } from '../../../../redux/actions/steps';

import Header2 from "../../../layout/header/Header";

const StepAdd = () => {
  const dispatch = useDispatch();

  const types = useSelector((state) => state.steps.options);

  let { id } = useParams();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const {
    register,
    control,
    handleSubmit,
  } = useForm();

  useEffect(() => {
    async function getData() {
      await dispatch(getStepsTypes());
    };

    getData();
  }, []);

  const onSubmit = (form) => {

    const {
      nome,
      descricao,
      tipo,
    } = form;

    const formData = new FormData();

    formData.append('nome', nome);
    formData.append('descricao', descricao);
    formData.append('inicio', (moment(startDate).format('YYYY-MM-DD')));
    formData.append('fim', (moment(endDate).format('YYYY-MM-DD')));
    formData.append('tipo', tipo.value);
    formData.append('sala', id);

    dispatch(addStep(formData, id));
  }

  return (
    <>
      <Header2 title={'Adicionar Fase'} />
      <br />
      <br />
      <div className="main-content">
        <div className="middle-sidebar-bottom-custom">
          <div className="middle-sidebar-left">
            <div className="row">
              <div className="col-xl-12">
                <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">

                      <div className="col-lg-12 mb-3">
                        <label className="mont-font fw-600 font-xsss mb-2">Tipo *</label>
                        <Controller
                          name="tipo"
                          control={control}
                          rules={{
                            required: true
                          }}
                          render={({ field }) => <Select
                            {...field}
                            placeholder="Selecione um tipo de fase..."
                            options={types}
                          />}
                        />
                      </div>

                      <div className="col-lg-12 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">Título *</label>
                          <input {...register("nome", { required: true })}
                            type="text"
                            className="form-control"
                            placeholder="Digite o título da fase..."
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 mb-3">
                        <label className="mont-font fw-600 font-xsss mb-2">Descrição *</label>
                        <textarea
                          {...register("descricao", { required: true })}
                          className={'h100 bor-0 w-100 rounded-xxl p-2 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg'}
                          cols="30"
                          rows="10"
                          placeholder="Escreva um pouco sobre a fase..."
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">Data Início</label>
                        <DatePicker
                          dateFormat="d/M/Y"
                          className="form-control"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">Data Término</label>
                        <DatePicker
                          dateFormat="d/M/Y"
                          className="form-control"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 ">
                      <input
                        className="bg-gradiant-dark-green text-center text-white font-xsss fw-600 p-3 border-0 w175 rounded-3 d-inline-block"
                        type="submit"
                        value={'Adicionar'}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StepAdd;
